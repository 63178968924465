import UserInfoActionTypes from "./userInfo_action_types";

const initialState = {
  userInfo: {},
  isUserLogin: false,
  deviceToken: "",
  isSessionExpired: false,
};

const UserInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case UserInfoActionTypes.USER_INFO:
      return {
        ...state,
        userInfo: action.payload,
      };
    case UserInfoActionTypes.IS_USER_LOGIN:
      return {
        ...state,
        isUserLogin: action.payload,
      };
    case UserInfoActionTypes.DEVICE_TOKEN:
      return {
        ...state,
        deviceToken: action.payload,
      };
    case UserInfoActionTypes.SESSION_EXPIRED:
      return {
        ...state,
        isSessionExpired: true,
      };
    default:
      return state;
  }
};

export default UserInfoReducer;
