import { useNavigate } from "react-router-dom";
import logo from "../../assets/Logo.svg";
const Logo = () => {
  const redirect = useNavigate();
  return (
    <div style={{ cursor: "pointer" }}>
      <img
        src={logo}
        alt="talalika Logo"
        width={180}
        onClick={() => {
          redirect("/landing");
          // window.location.reload(); 
        }}
      />
    </div>
  );
};
export default Logo;
