import { capitalizeString } from "../../../common/StringOpration/capitalizeString";

const EditProfileForm = ({
  userDetails,
  handleFieldChange,
  cancelUpdationHandler,
  handleUpdateProfile,
  windowWidth,
}) => {
  return (
    <>
      <div className="flex">
        {/* Left Side */}
        <div className="flex flex-col gap-8 ml-10">
          <div
            className={`flex flex-col bg-gray-200 px-4 py-2 rounded-xl ${
              windowWidth < 1050 ? "w-fit" : "w-96"
            }`}
          >
            <label
              className="text-blue-700"
              style={{ fontWeight: "500" }}
              htmlFor="name"
            >
              First Name
            </label>
            <input
              type="text"
              placeholder="John"
              name="firstName"
              onChange={(e) => handleFieldChange(e)}
              className="border-none p-0 bg-transparent focus:ring-0 focus:outline-none hover:border-none active:border-none"
              value={capitalizeString(userDetails?.firstName.value)}
              id="name"
            />
          </div>

          <div
            className={`flex flex-col bg-gray-200 px-4 py-2 rounded-xl ${
              windowWidth < 1050 ? "w-fit" : "w-96"
            }`}
          >
            <label
              className="text-blue-700"
              style={{ fontWeight: "500" }}
              htmlFor="num"
            >
              Country Code
            </label>
            <input
              type="text"
              placeholder="+205"
              name="countryCode"
              onChange={(e) => handleFieldChange(e)}
              className="border-none p-0 bg-transparent focus:ring-0 focus:outline-none hover:border-none active:border-none"
              value={userDetails?.countryCode.value}
              id="num"
            />
          </div>

          <div
            className={`flex flex-col bg-gray-200 px-4 py-2 rounded-xl ${
              windowWidth < 1050 ? "w-fit" : "w-96"
            }`}
          >
            <label
              className="text-blue-700"
              style={{ fontWeight: "500" }}
              htmlFor="email"
            >
              Email
            </label>
            <input
              type="text"
              placeholder="example@email.com"
              name="email"
              onChange={(e) => handleFieldChange(e)}
              className="border-none p-0 bg-transparent focus:ring-0 focus:outline-none hover:border-none active:border-none"
              value={userDetails?.email.value}
              id="email"
            />
          </div>

          {windowWidth > 1350 && (
            <div className="flex justify-between items-center gap-3 ">
              <button
                className="border w-1/2 py-2 rounded-full border-blue-500 text-blue-500 hover:bg-blue-100"
                style={{ fontWeight: "500" }}
                onClick={() => cancelUpdationHandler()}
              >
                Cancel
              </button>
              <button
                className="border w-1/2 py-2 rounded-full bg-blue-700 border-blue-500 text-white hover:bg-blue-800"
                style={{ fontWeight: "500" }}
                onClick={() => handleUpdateProfile()}
              >
                Save
              </button>
            </div>
          )}
        </div>
        {/* Right Side */}
        <div className="flex flex-col gap-8 ml-10">
          <div
            className={`flex flex-col bg-gray-200 px-4 py-2 rounded-xl ${
              windowWidth < 1050 ? "w-fit" : "w-96"
            }`}
          >
            <label
              className="text-blue-700"
              style={{ fontWeight: "500" }}
              htmlFor="name"
            >
              Last Name
            </label>
            <input
              type="text"
              placeholder="Doe"
              name="lastName"
              onChange={(e) => handleFieldChange(e)}
              className="border-none p-0 bg-transparent focus:ring-0 focus:outline-none hover:border-none active:border-none"
              value={capitalizeString(userDetails?.lastName.value)}
              id="name"
            />
          </div>

          <div
            className={`flex flex-col bg-gray-200 px-4 py-2 rounded-xl ${
              windowWidth < 1050 ? "w-fit" : "w-96"
            }`}
          >
            <label
              className="text-blue-700"
              style={{ fontWeight: "500" }}
              htmlFor="num"
            >
              Mobile Number
            </label>
            <input
              type="text"
              placeholder="555-0100"
              name="phoneNo"
              onChange={(e) => handleFieldChange(e)}
              className="border-none p-0 bg-transparent focus:ring-0 focus:outline-none hover:border-none active:border-none"
              value={userDetails?.phoneNo.value}
              id="num"
            />
          </div>
        </div>
      </div>
      {windowWidth < 1350 && (
        <div className="flex justify-between items-center gap-3 w-96 mt-8">
          <button
            className="border w-1/2 py-2 rounded-full border-blue-500 text-blue-500 hover:bg-blue-100"
            style={{ fontWeight: "500" }}
            onClick={() => cancelUpdationHandler()}
          >
            Cancel
          </button>
          <button
            className="border w-1/2 py-2 rounded-full bg-blue-700 border-blue-500 text-white hover:bg-blue-800"
            style={{ fontWeight: "500" }}
            onClick={() => handleUpdateProfile()}
          >
            Save
          </button>
        </div>
      )}
    </>
  );
};
export default EditProfileForm;
