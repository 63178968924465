import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

import axios from "../../constant/axios";
import "./upload-button.scss";
import { toast } from "react-toastify";

import classNames from "classnames";

const styles = {
  input: "custom-upload-button__input",
  label: "custom-upload-button__label",
  loader: "custom-upload-button__loader",
  disabled: "custom-upload-button__disabled",
};
function UploadButton(props) {
  const inputRef = useRef(null);
  const [isLoading, changeIsLoading] = useState(false);
  const [imgUrl, setImgUrl] = useState("");
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      toast.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      toast.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleimgUpload = (info) => {
    //!console.log("handleimgUpload => ", info)
    if (info.file.status === "uploading") {
      changeIsLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        changeIsLoading(false);
        setImgUrl(url);
      });
    }
  };

  const uploadFile = (file) => {
    if (file) {
      changeIsLoading(true);
    }
    const data = new FormData();
    data.append("file", file);
    axios
      .post("/fileUpload", data)
      .then((res) => {
        if (res.statusCode >= 200 && res.statusCode <= 299) {
          //!console.log("Upload file = > ", res?.data?.data)
          props.onChange(res?.data?.location[0]);

          changeIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        changeIsLoading(false);
      });
  };

  const handleFile = (file) => {
    const reader = new FileReader();
    reader.onload = function (e) {
      props.onChange(e.target.result);
    };
    if (props.resultType === "binary") {
      reader.readAsArrayBuffer(file);
    } else if (props.resultType === "base64") {
      reader.readAsDataURL(file);
    } else if (props.resultType === "url") {
      uploadFile(file);
    }
  };
  const handleChange = (ev) => {
    handleFile(ev.target.files[0]);
  };

  const clearInput = (e) => {
    e.target.value = null;
  };
  return (
    <>
      <input
        key={props.key}
        disabled={isLoading || props?.disabled}
        onClick={clearInput}
        ref={inputRef}
        id={`${props.id}uploadFile`}
        className={styles.input}
        name={`${props.id}uploadFile`}
        type="file"
        accept={props.accept}
        onChange={handleChange}
      />

      {true ? (
        <label
          className={classNames({
            [props.className]: true,
            [styles.loader]: isLoading,
          })}
          htmlFor={!isLoading ? `${props.id}uploadFile` : ""}
        >
          {!isLoading ? props.text : "Loading..."}
        </label>
      ) : imgUrl ? (
        <img
          src={imgUrl}
          alt="avatar"
          style={{
            width: "100%",
          }}
        />
      ) : (
        "button"
      )}
    </>
  );
}

UploadButton.propTypes = {
  resultType: PropTypes.oneOf(["binary", "base64", "url"]).isRequired,
  accept: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string,
  parentRef: PropTypes.object,
};

UploadButton.defaultProps = {
  resultType: "base64",
  accept: "*",
  text: "Upload Image",
  parentRef: {
    current: null,
  },
  onChange: () => {},
};

export default UploadButton;
