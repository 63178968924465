import React from "react";
import { useNavigate } from "react-router-dom";
import "./main.scss";
import { getToken } from "../../constant/localStorage/localStorage";
import LogoWhite from "../../assets/LogoWhite.svg";
import bg from "../../assets/BG_3.png";
import { el } from "date-fns/locale";

const Main = () => {
  const redirect = useNavigate();
  const handleContinue = () => {
    let token = getToken();
    if (!token) {
      redirect("/login");
    } else {
      redirect("/landing");
    }
  };
  return (
    <div
      className="flex justify-center items-center m-0 p-0"
      style={{
        height: "100vh",
        background: `url(${bg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className="flex flex-col items-center gap-6">
        <div>
          <img src={LogoWhite} alt="" width={500} />
        </div>
        <div
          className="text-[1.25rem] border border-white rounded-full w-4/12 text-center py-2 cursor-pointer text-white mt-2"
          onClick={handleContinue}
        >
          Continue
        </div>
      </div>
    </div>
  );
};

export default Main;
