import bg from "../../../assets/BG_4.png";
import bg2 from "../../../assets/BG_5.png";
const AboutCompany = () => {
  return (
    <div className="overflow-scroll">
      <h1 className="font-bold text-xl py-3">About Company</h1>
      <div style={{ height: "10%" }} className="overflow-y-scroll px-5">
        <div
          className="flex flex-col justify-center items-center rounded-3xl"
          style={{
            background: `url(${bg})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100%",
            height: "325px",
          }}
        >
          <h1
            className="text-white font-bold px-44 text-center"
            style={{ fontSize: "30px" }}
          >
            Paradise Awaits you at Casa Villa Verde, Puerto Vallarta’s Premier
            Rental Estate
          </h1>
          <p
            className="text-white px-44 text-center mt-4"
            style={{ fontSize: "15px" }}
          >
            This magnificent six bedroom, old Vallarta-style beachfront villa is
            located in the heart of Conchas Chinas, an exquisite beach located
            just south of downtown. With over 12,000 square feet of living
            space, Casa Villa Verde, “The Green Villa”, offers Mexican luxury at
            its finest.
          </p>
        </div>
        <div className="flex justify-center mt-8">
          <div className="w-1/2 px-10">
            <h1
              className="px-3"
              style={{ fontWeight: "600", fontSize: "18px" }}
            >
              Enjoy a leisurely 15 minute walk along the water to reach Playa
              Los Muertos and the city of Puerto Vallarta.
            </h1>
            <p className="px-3 w-full mt-8" style={{ fontSize: "15px" }}>
              Casa Villa Verde is located approximately 30 minutes from the
              Vallarta airport. Taxis are readily available and can be
              coordinated through your concierge.
            </p>
          </div>
          <img src={bg2} alt="" width="500" className="mb-10" />
        </div>
      </div>
    </div>
  );
};

export default AboutCompany;
