import { useSelector } from "react-redux";
import discount from "../../assets/checkout/coupon.svg";
import CartCard from "../checkout/LeftContainer/CartCard";
import { useEffect, useState } from "react";
import { getToken } from "../../constant/localStorage/localStorage";
import EmptyCart from "../checkout/EmptyCart";

const CartModal = ({ isCartVisible, handleSubmit, onMouseLeave }) => {
  const token = getToken();

  const [serviceDeleteFlag, setServiceDeleteFlag] = useState(false);

  const cartTotal = useSelector((state) => state.cart.cartTotal);

  const cart = useSelector((state) => state.cart.cartData);

  const [orders, setOrders] = useState([]);

  const handleSetOrders = (data) => {
    setOrders(data);
  };

  useEffect(() => {
    if (token && cart) {
      handleSetOrders(cart?.cartItems);
    }
  }, [token, cart]);

  const handleMouseLeave = () => {
    console.log("serviceDeleteFlag => ", serviceDeleteFlag);
    if (!serviceDeleteFlag) {
      onMouseLeave();
    }
  };

  console.log(cartTotal);

  return (
    <>
      {isCartVisible ? (
        <>
          <div
            className="absolute p-3 top-12 right-52 mt-2 rounded-lg bg-white shadow-lg flex flex-col z-20"
            style={{
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              width: "400px",
            }}
            onMouseLeave={() => handleMouseLeave()}
          >
            {orders?.length > 0 ? (
              <>
                <div className="overflow-scroll" style={{ maxHeight: "13rem" }}>
                  {orders &&
                    orders.map((item, index) => {
                      return (
                        <CartCard
                          item={item}
                          conpact={true}
                          key={item?.id}
                          setServiceDeleteFlag={setServiceDeleteFlag}
                          onMouseLeave={onMouseLeave}
                        />
                      );
                    })}
                </div>

                {/* <div className="bg-gray-100 rounded-lg flex justify-between px-4 py-1 w-full mt-4">
                  <input
                    type="text"
                    placeholder="Apply Promo"
                    className="bg-transparent border-none outline-none w-full focus:outline-none focus:border-none active:outline-none active:border-none"
                  />

                  <img src={discount} alt="" />
                </div> */}

                <h1 className="font-medium mt-3">Amount Breakdown</h1>
                <div className="flex justify-between items-center">
                  <p>Price</p>
                  <p>${cartTotal?.totalActualPrice}</p>
                </div>
                <div className="flex justify-between items-center mt-1">
                  <p>Discount</p>
                  <p>- ${cartTotal?.discount}</p>
                </div>

                <div className="flex justify-between items-center mt-1">
                  <p>Promo Discount</p>
                  <p>- ${cartTotal?.couponDiscount}</p>
                </div>
                <div className="flex justify-between items-center mt-1 pb-2">
                  <p>Overall Discount</p>
                  <p>- ${cartTotal?.overallDiscount}</p>
                </div>
                <div
                  className="flex justify-between items-center mt-1 py-2"
                  style={{
                    borderTop: "1px solid gray",
                    borderBottom: "1px solid gray",
                  }}
                >
                  <p className="font-medium">Grand Total</p>
                  <p className="font-medium">
                    ${cartTotal?.totalServicePayable}
                  </p>
                </div>
                <button
                  className="w-full px-10 py-2 bg-[#1560BD] flex justify-center items-center mt-3 text-white rounded-full cursor-pointer hover:bg-[#0E4A9B]"
                  onClick={handleSubmit}
                >
                  Go to Checkout
                </button>
              </>
            ) : (
              <EmptyCart
                title="There are no Activities Available"
                description="Available activities will show here"
                conpact={true}
              />
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default CartModal;
