import ImageSlider from "../../components/Home/ImageSlider/ImageSlider";
import Footer from "../../components/Footer/Footer";
import Activity from "../../components/Home/Activity/Activity";
import axios from "../../constant/axios";
import { useEffect, useState } from "react";
import Popular from "../../components/Home/Popular/popular";
import Chat from "./Chat";

const Home = () => {
  const [sliderImages, setSliderImages] = useState([]);
  const [activities, setActivities] = useState([]);
  const [category, setCategory] = useState();

  const getAllCategory = () => {
    let querry = {
      param: {
        isEnabled: true,
      },
    };
    axios.get("/category", querry).then((res) => {
      if (res?.statusCode >= 200 && res?.statusCode <= 299) {
        setCategory(res?.data?.categories);
      }
    });
  };

  useEffect(() => {
    // Getting All Banners Data
    getAllCategory();
    axios
      .get("/banners")
      .then((res) => {
        setSliderImages(res.data.banners);
      })
      .catch((err) => {
        console.log(err);
      });

    // Getting All Services Data
    axios
      .get("/services?status=true&serviceTypeTag=POPULAR")
      .then((res) => {
        setActivities(res.data.services);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Chat />
      <ImageSlider images={sliderImages} interval={4000} />
      <Activity activities={activities} />
      {/* Activity is Popular Now */}
      <Popular activities={category} />
      {/* Popular is Categories now */}
      <Footer />
    </>
  );
};
export default Home;
