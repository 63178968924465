import ScreenLoaderTypes from "./screen-loader-types";
const initialState = {
  isLoading: false,
};

const ScreenLoaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case ScreenLoaderTypes.ScreenLoading:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

export default ScreenLoaderReducer;
