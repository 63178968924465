const TermsAndConditions = () => {
  return (
    <>
      <div class="container mx-auto p-4">
        <h1 class="text-3xl font-bold mb-4">Terms and Conditions</h1>
        <ul class="list-disc ml-6">
          <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
          <li>
            Nulla facilisi. Praesent at nulla vel velit vulputate pharetra.
          </li>
          <li>Etiam ut felis sed elit ultricies dignissim.</li>
          <li>Quisque feugiat nec leo at tincidunt.</li>
          <li>Vestibulum fermentum justo at aliquam bibendum.</li>
        </ul>
      </div>
    </>
  );
};
export default TermsAndConditions;
