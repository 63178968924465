import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ActivityCards from "../Activity/ActivityCards";
import axiosClient from "../../../constant/axios";
import PopularCard from "./PopularCard";

const Popular = ({ activities }) => {
  console.log("PopularCard => ", activities);

  const redirect = useNavigate();

  return (
    <div className="mx-40">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Categories</h1>
        <p
          className="text-sm underline cursor-pointer"
          style={{ fontWeight: "500" }}
          onClick={() => redirect("/categories")}
        >
          View All
        </p>
      </div>
      <PopularCard
        cardsData={!activities ? "" : activities}
        popularCategory={true}
      />
    </div>
  );
};

export default Popular;
