import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
const SkeletonLoad = () => {
  return (
    <>
      <Box>
        <Skeleton variant="rounded" width={"100%"} height={160} />
      </Box>
      <Box sx={{ width: "100%", margin: "26px 0px" }}>
        <Skeleton />
        <Skeleton animation="wave" />
        <Skeleton animation={false} />
      </Box>
    </>
  );
};
export default SkeletonLoad;
