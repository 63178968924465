import CartActionTypes from "./cart_action_type";

const initialState = {
  cartData: {},
  cartTotal: {},
  promoCodes: [],
  paymentMethods: [],
};

const CartReducer = (state = initialState, action) => {
  switch (action.type) {
    case CartActionTypes.CART_DETAIL:
      return {
        ...state,
        cartData: action.payload,
      };
    case CartActionTypes.CART_TOTAL:
      return {
        ...state,
        cartTotal: action.payload,
      };
    case CartActionTypes.PROMO:
      return {
        ...state,
        promoCodes: action.payload,
      };
    case CartActionTypes.PAYMNET_CARD:
      return {
        ...state,
        paymentMethods: action.payload,
      };
    default:
      return state;
  }
};

export default CartReducer;
