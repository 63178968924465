import React, { useState, useEffect } from "react";
// import { myProfile, history, payment } from "../icon/svgIcons";
import SVGIcons from "../../constant/svgIcon/svgIcons";

const {
  myProfile,
  history,
  messageIcon,
  cardDelete,
  payment,
  arrow,
  VISAIcon,
  editIcon,
  referAndEarn,
  pdf,
  download,
  homeIcon,
  navigation_Logo,
  servicearound,
  camera,
  cancelBookingIcon,
  gloabIcon,
  nowIcon,
  scheduleIcon,
  requestQuote,
  verified,
  cartEmpty,
  Notification,
  RateInstaservice,
  Help,
  ChangeLanguage,
  RE,
  RC,
  Quotation,
  BrandLogo,
  bookingHistory,
  about,
  paymentSuccess,
  paymentCancel
} = SVGIcons;

const Icon = (props) => {
  const [icon, setIcon] = useState("");
  const handleSVGIcons = (name) => {
    if (name === "DASHBOARD") {
      setIcon(myProfile);
    } else if (name === "STORE") {
      setIcon(history);
    } else if (name === "ROLE_MANAGEMENT") {
      setIcon(payment);
    } else if (name === "arrow") {
      setIcon(arrow);
    } else if (name === "visa_card") {
      setIcon(VISAIcon);
    } else if (name === "cardDelete") {
      setIcon(cardDelete);
    } else if (name === "message_icon") {
      setIcon(messageIcon);
    } else if (name === "editIcon") {
      setIcon(editIcon);
    } else if (name === "referAndEarn") {
      setIcon(referAndEarn);
    } else if (name === "pdf") {
      setIcon(pdf);
    } else if (name === "download") {
      setIcon(download);
    } else if (name === "servicearound") {
      setIcon(servicearound);
    } else if (name === "homeIcon") {
      setIcon(homeIcon);
    } else if (name === "navigation_Logo") {
      setIcon(navigation_Logo);
    } else if (name === "camera") {
      setIcon(camera);
    } else if (name === "cancelBookingIcon") {
      setIcon(cancelBookingIcon);
    } else if (name === "gloabIcon") {
      setIcon(gloabIcon);
    } else if (name === "nowIcon") {
      setIcon(nowIcon);
    } else if (name === "scheduleIcon") {
      setIcon(scheduleIcon);
    } else if (name === "requestQuote") {
      setIcon(requestQuote);
    } else if (name === "verified") {
      setIcon(verified);
    } else if (name === "cartEmpty") {
      setIcon(cartEmpty);
    } else if (name === "Notification") {
      setIcon(Notification);
    } else if (name === "RateInstaservice") {
      setIcon(RateInstaservice);
    } else if (name === "Help") {
      setIcon(Help);
    } else if (name === "ChangeLanguage") {
      setIcon(ChangeLanguage);
    } else if (name === "RE") {
      setIcon(RE);
    } else if (name === "RC") {
      setIcon(RC);
    } else if (name === "Quotation") {
      setIcon(Quotation);
    } else if (name === "BrandLogo") {
      setIcon(BrandLogo);
    } else if (name === "about") {
      setIcon(about);
    } else if (name === "bookingHistory") {
      setIcon(bookingHistory);
    }
    // else if (name === "paymentSuccess") {
    //   setIcon(paymentSuccess);
    // }
    else if (name === "paymentCancel") {
      setIcon(paymentCancel);
    }
    
    else {
      setIcon(history);
    }
  };
  useEffect(() => {
    handleSVGIcons(props.name);
  }, [props.name]);

  return (
    // <div>
    icon ? icon : ""
    // </div>
  );
};

export default Icon;
