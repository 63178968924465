import React, { useState } from "react";
import close from "../../../assets/ui_elements/delete.svg";
import Validations from "../../../helper/validations/validations";

const PromoCodeModal = ({
  discountVouchers,
  setCopan,
  handelCopanDiscount,
  setDisplayOffersModal,
}) => {
  const [selectedVoucher, setSelectedVoucher] = useState(null);

  console.log("discountVouchers => ", discountVouchers);

  const selectVoucher = (voucher) => {
    setSelectedVoucher(voucher);
    handelCopanDiscount(voucher.code);
    setCopan(Validations.validateTextField(voucher.code));
  };

  return (
    <>
      <div className="px-4 flex items-center gap-4 mb-4 bg-gray-100 mx-4 rounded-md">
        <input
          placeholder="Apply Promo"
          disabled
          value={selectedVoucher !== null ? selectedVoucher.code : ""}
          className=" border-none h-12 grow"
        />

        {selectedVoucher !== null && (
          <>
            <button
              className="px-3 py-0 border border-blue-500 text-blue-500 rounded-full"
              onClick={() => setDisplayOffersModal(false)}
            >
              Apply
            </button>
            <img
              src={close}
              alt=""
              width={20}
              className="cursor-pointer"
              onClick={() => {
                setSelectedVoucher(null);
                setCopan({
                  value: "",
                  isValid: false,
                  errMsg: [],
                });
              }}
            />
          </>
        )}
      </div>
      <div className="px-4 py-4 pt-1 h-48 rounded-lg overflow-y-auto">
        {discountVouchers?.map((voucher, index) => (
          <div
            key={index}
            className={`border border-[#99C7FF] px-4 py-2 rounded-[0.6rem] bg-[#ECF5FF] flex justify-between items-center mb-4 ${
              selectedVoucher === voucher ? "bg-blue-200" : ""
            }`}
          >
            <div>
              <h1 className="font-bold">{voucher.title}</h1>
              <p style={{ fontSize: "13px" }}>{voucher.description}</p>
            </div>
            <input
              type="radio"
              name="voucher"
              className="rounded-full"
              checked={selectedVoucher === voucher}
              onChange={() => selectVoucher(voucher)}
            />
          </div>
        ))}
      </div>
    </>
  );
};

export default PromoCodeModal;
