import { useState, useEffect } from "react";
import axios from "../../constant/axios";
import ServicesCard from "./ServicesCard";
import Footer from "../../components/Footer/Footer";
const Services = ({ pickupService = false }) => {

  console.log("pickupService =====>" , pickupService)
  const [activities, setActivities] = useState([]);
  useEffect(() => {
    let url = "/services?status=true&serviceTypeTag=POPULAR";
    if (pickupService) {
      url = "/services?status=true&isPickupService=true";
    }
    // Getting All Services Data
    axios
      .get(url)
      .then((res) => {
        setActivities(res.data.services);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="mt-8 mx-40">
        <h1 className="text-2xl font-bold">
          {
            pickupService ? "Airport transfers": "Activities"
          }
    
          </h1>
        <ServicesCard cardsData={activities} />
      </div>
      <Footer />
    </>
  );
};
export default Services;
