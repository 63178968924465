import { useSelector } from "react-redux";
import verified from "../../../assets/ui_elements/verified.svg";
import styles from "./Verify.module.css";
const DisplayProfile = ({ userDetails, setEdit }) => {
  const verificationDetails = useSelector((state) => state.user.userInfo);
  console.log(verificationDetails);
  return (
    <>
      <div className="flex flex-col gap-8 ml-10">
        <div className="flex flex-col bg-gray-200 px-4 py-2 rounded-xl w-96">
          <label
            className="text-blue-700"
            style={{ fontWeight: "500" }}
            htmlFor="name"
          >
            Name
          </label>
          <input
            type="text"
            placeholder="John Doe"
            className="border-none p-0 bg-transparent"
            value={`${userDetails?.firstName.value} ${userDetails?.lastName.value}`}
            id="name"
            disabled
          />
        </div>

        <div className="flex bg-gray-200 px-4 py-2 rounded-xl w-96">
          <div className="flex flex-col w-full">
            <label
              className="text-blue-700"
              style={{ fontWeight: "500" }}
              htmlFor="email"
            >
              Email
            </label>
            <input
              type="text"
              placeholder="example@email.com"
              className="border-none p-0 bg-transparent"
              value={userDetails?.email.value}
              id="email"
              disabled
            />
          </div>
          {verificationDetails.isEmailVerified && (
            <div className={`cursor-pointer ${styles.tooltip}`}>
              <img src={verified} alt="" width={25} className="mt-3" />
              <span className={styles.tooltiptext}>Email Verified</span>
            </div>
          )}
        </div>

        <div className="flex bg-gray-200 px-4 py-2 rounded-xl w-96">
          <div className="flex flex-col w-full">
            <label
              className="text-blue-700"
              style={{ fontWeight: "500" }}
              htmlFor="num"
            >
              Mobile Number
            </label>
            <input
              type="text"
              placeholder="(205) 555-0100"
              className="border-none p-0 bg-transparent"
              value={`(${userDetails?.countryCode.value}) ${userDetails?.phoneNo.value}`}
              id="num"
              disabled
            />
          </div>
          {verificationDetails.isPhoneVerified && (
            <div className={`cursor-pointer ${styles.tooltip}`}>
              <img src={verified} alt="" width={25} className="mt-3" />
              <span className={styles.tooltiptext}>Mobile Verified</span>
            </div>
          )}
        </div>

        <div className="flex justify-between items-center gap-3 ">
          <button
            className="border w-full py-2 rounded-full bg-blue-700 border-blue-500 text-white hover:bg-blue-800"
            style={{ fontWeight: "500" }}
            onClick={() => setEdit(true)}
          >
            Edit
          </button>
        </div>
      </div>
    </>
  );
};
export default DisplayProfile;
