import classNames from "classnames";
import React, { useState, useEffect } from "react";
import defaultImage from "../../../assets/avatar.png";
import Icon from "../../../common/Icon/icon";
import Validation from "../../../helper/validations/validations";
import UserAction from "../../../redux/userInfo/userInfo-action";
import { connect } from "react-redux";
import axios from "axios";
import LogoutModal from "../../logoutModal/LogoutModal";
import { toast } from "react-toastify";
import Photo from "./Photo";
import DisplayProfile from "./DisplayProfile";
import EditProfileForm from "./EditProfileForm";

const Myprofile = ({ userInfo, updateCustomer, ...props }) => {
  const [imageDataUrl, setImageDataUrl] = useState("");

  const [isEdit, setEdit] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [userDetails, setUserDetails] = useState({
    firstName: Validation.validateTextField(""),
    lastName: Validation.validateTextField(""),
    countryCode: Validation.validateTextField(""),
    phoneNo: Validation.validatePhoneNumber(""),
    email: Validation.validateEmail(""),
    old_phoneNo: Validation.validatePhoneNumber(),
    old_email: Validation.validateEmail(),
    userId: Validation.validateNumericValue(),
  });

  const [modalControl, setModalControl] = useState({
    isVisible: false,
    logoutmodalIsVisible: false,
    data: [],
  });

  const handleClose = () => {
    setModalControl({
      isVisible: false,
      logoutmodalIsVisible: false,
      data: [],
    });
  };
  const handleFieldChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "firstName":
        setUserDetails({
          ...userDetails,
          firstName: Validation.validateTextField(value),
        });

        break;
      case "lastName":
        setUserDetails({
          ...userDetails,
          lastName: Validation.validateTextField(value),
        });

        break;
      case "countryCode":
        setUserDetails({
          ...userDetails,
          countryCode: Validation.validateTextField(value),
        });

        break;
      case "email":
        setUserDetails({
          ...userDetails,
          email: Validation.validateEmail(value),
        });

        break;
      case "phoneNo":
        setUserDetails({
          ...userDetails,
          phoneNo: Validation.validatePhoneNumber(value),
        });

        break;
      default:
        return;
    }
  };

  const handleSetUserInfo = (userInfo) => {
    setUserDetails({
      ...userDetails,
      firstName: Validation.validateTextField(userInfo.firstName),
      lastName: Validation.validateTextField(userInfo.lastName),
      countryCode: Validation.validateTextField(userInfo.countryCode),
      phoneNo: Validation.validatePhoneNumber(userInfo.phoneNo),
      email: Validation.validateEmail(userInfo.email),
      old_phoneNo: Validation.validatePhoneNumber(userInfo.phoneNo),
      old_email: Validation.validateEmail(userInfo.email),
      userId: Validation.validateNumericValue(userInfo.id),
    });
    setImageDataUrl(userInfo.profilePicURL);
  };

  useEffect(() => {
    if (userInfo) {
      handleSetUserInfo(userInfo);
    }
  }, [userInfo]);

  const cancelUpdationHandler = () => {
    setEdit(false);
    if (userInfo) {
      handleSetUserInfo(userInfo);
    }
  };

  const handleUpdateProfile = (data) => {
    console.log(userDetails);

    let payload = {
      profilePicURL: imageDataUrl,

      countryCode: userDetails?.countryCode?.value,
      firstName: userDetails?.firstName?.value,
      lastName: userDetails?.lastName?.value,
      email: userDetails?.email?.value,
      phoneNo: userDetails?.phoneNo?.value,
    };
    updateCustomer(payload);
    setEdit(false);

    // payload.firstName = userDetails.firstName.value;
    // payload.lastName = userDetails.lastName.value;

    // if (imageDataUrl) {
    //   payload.profilePicURL = imageDataUrl;
    // }
    // let api = "Change you API here ";
    // axios
    //   .put(api, payload)
    //   .then((res) => {
    //     console.log(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  // const handleSendOtp = (payload) => {
  //   //! ======================= Testing ==============
  //   setModalControl({
  //     ...modalControl,
  //     isVisible: true,

  //     phoneNo: userDetails?.phoneNo?.value,
  //     email: userDetails?.email.value,
  //     countryCodes: userDetails?.countryCode?.value,
  //   });

  //   let api = " uncomment and change to your API";
  //   axios
  //     .put(api, payload)
  //     .then((res) => {
  //       if (res?.statusCode >= 200 && res?.statusCode <= 299) {
  //         let data = {
  //           old_email: userDetails?.old_email.value,
  //           old_phoneNo: userDetails?.old_phoneNo.value,
  //           email: userDetails?.email.value,
  //           countryCodes: userDetails?.countryCode?.value,
  //           phoneNo: userDetails?.phoneNo.value,
  //           id: userDetails?.userId?.value,
  //         };
  //         setModalControl({
  //           ...modalControl,
  //           isVisible: true,
  //           data: data,
  //           phoneNo: userDetails?.phoneNo?.value,
  //           email: userDetails?.email.value,
  //           countryCodes: userDetails?.countryCode?.value,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  // const handleSubmit = () => {
  //   if (
  //     userDetails.old_phoneNo.value === userDetails.phoneNo.value &&
  //     userDetails.old_email.value === userDetails.email.value
  //   ) {
  //     let payload = {};
  //     handleUpdateProfile(payload);
  //   } else {
  //     let payload = {
  //       phoneNo: userDetails.phoneNo.value,
  //       countryCode: userDetails.countryCode.value,
  //     };

  //     if (userDetails.old_email.value !== userDetails.email.value) {
  //       payload.email = userDetails.email.value;
  //     }
  //     handleSendOtp(payload);
  //   }
  //   setEdit(false);
  // };

  return (
    <>
      <LogoutModal
        isVisible={modalControl?.logoutmodalIsVisible}
        handleClose={handleClose}
        title={"Logout"}
      />
      <div className="w-full">
        <h1 className="font-bold text-xl py-3">Edit Profile</h1>
        <div
          className={`flex ${
            windowWidth < 1350 && "flex-col justify-center items-center"
          }`}
        >
          <Photo
            setImageDataUrl={setImageDataUrl}
            imageDataUrl={imageDataUrl}
            defaultImage={defaultImage}
            userDetails={userDetails}
            isEdit={isEdit}
          />
          {isEdit ? (
            <EditProfileForm
              userDetails={userDetails}
              handleFieldChange={handleFieldChange}
              cancelUpdationHandler={cancelUpdationHandler}
              handleUpdateProfile={handleUpdateProfile}
              windowWidth={windowWidth}
            />
          ) : (
            <DisplayProfile userDetails={userDetails} setEdit={setEdit} />
          )}
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  // getUserInfo: () => dispatch(UserAction.getUserInfo()),
  updateCustomer: (val) => dispatch(UserAction.updateCustomer(val)),
});

const mapStateToProps = (state) => ({
  userInfo: state.user.userInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(Myprofile);
