import { useState } from "react";
import Modal from "../../../common/Modal/modal";
import AddToCartModal from "./AddToCartModal";

import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { capitalizeString } from "../../../common/StringOpration/capitalizeString";

const ActivityCards = ({ cardsData, popularCategory }) => {
  const [bookingModal, setBookingModal] = useState(false);
  const [cartId, setCartId] = useState();

  const openBookingModalHandler = (id) => {
    setCartId(id);
    setBookingModal(true);
  };
  const handleClose = () => {
    setBookingModal(false);
  };
  const handleClick = () => {};
  return (
    <>
      <div className="flex items-center flex-wrap gap-12 my-5">
        {cardsData?.length === 0 && (
          <>
            {Array.from({ length: 6 }).map((_, index) => (
              <Box key={index}>
                <Skeleton variant="rounded" width={160} height={160} />
              </Box>
            ))}
          </>
        )}
        {cardsData?.slice(0, 6).map((item, index) => {
          return (
            <div
              key={index}
              className="w-40 h-40 relative border bg-black rounded-lg cursor-pointer"
              style={{
                background: `url(${item.serviceImg})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
              onClick={
                popularCategory
                  ? () => handleClick()
                  : () => openBookingModalHandler(item.id)
              }
            >
              <div className="absolute inset-0 flex flex-col items-center justify-end">
                <h1 className="relative text-center p-2 w-full text-white bg-gray-400 bg-opacity-50 backdrop-blur-lg rounded-b-lg">
                  {capitalizeString(item?.title)}
                </h1>
              </div>
            </div>
          );
        })}
      </div>

      <Modal
        isVisible={bookingModal}
        onClose={handleClose}
        children={<AddToCartModal cartId={cartId} handleClose={handleClose} />}
      />
    </>
  );
};

export default ActivityCards;
