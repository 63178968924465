import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { useNavigate } from "react-router-dom";
import { capitalizeString } from "../../common/StringOpration/capitalizeString";

const CategoriesCard = ({ cardsData }) => {
  const styles = {
    overflow: "hidden",
    "text-overflow": "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": "3",
    "-webkit-box-orient": "vertical",
  };

  const redirect = useNavigate();

  return (
    <>
      <div className="flex justify-start items-center flex-wrap gap-6 my-5">
        {cardsData.length === 0 && (
          <>
            {Array.from({ length: 6 }).map((_, index) => (
              <Box key={index}>
                <Skeleton variant="rounded" width={384} height={384} />
              </Box>
            ))}
          </>
        )}
        {cardsData?.map((item, index) => {
          return (
            <div
              key={index}
              className="w-96 h-96 relative border bg-black rounded-lg cursor-pointer"
              style={{
                background: `url(${item.categoryIcon})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="absolute inset-0 flex flex-col items-center justify-end">
                <h1 className="relative flex justify-between items-center text-center p-2 px-5 w-full text-white bg-gray-400 bg-opacity-50 backdrop-blur-lg rounded-b-lg">
                  <p
                    className="text-left"
                    style={{
                      ...styles,
                      fontSize: "13px",
                      width: "60%",
                      textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                    }}
                  >
                    {capitalizeString(item.title)}
                  </p>

                  <button
                    className="bg-blue-500 px-3 py-1 rounded-md"
                    style={{ fontSize: "13px" }}
                    onClick={() => redirect(`/category/${item.id}/service`)}
                  >
                    View Activities
                  </button>
                </h1>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
export default CategoriesCard;
