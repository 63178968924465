import logo from "../../assets/Logo.svg";

const Footer = () => {
  return (
    <footer className="p-4 md:p-10 flex flex-col md:flex-row justify-center md:justify-around items-center md:items-start bg-slate-100 bg-gray-50">
      <img src={logo} alt="" className="w-48  hidden md:block" />
    </footer>
  );
};

export default Footer;
