import Modal from "../../../common/Modal/modal";
import Star from "../../../assets/ui_elements/Star.svg";
import Star2 from "../../../assets/ui_elements/Star2.svg";
import { useState } from "react";
import axiosClient from "../../../constant/axios";
import { toast } from "react-toastify";

const rate = [1, 2, 3, 4, 5];

const RateUsModal = ({ displayRateUsModal, handleClose, bookingId }) => {
  const [ratting, setRatting] = useState(0);
  const [text, setText] = useState("");
  console.log("RateUsModal => ", ratting + "  : " + text);

  const submitReview = () => {
    if (ratting && text) {
      let payload = {
        title: "N/A",
        content: text,
        bookedServiceId: bookingId,
        rating: ratting,
      };
      axiosClient
        .post(`/customers/submitReview`, payload)
        .then((res) => {
          if (res?.statusCode >= 200 && res?.statusCode <= 299) {
            handleClose();
            toast.success("Review Submitted 👌");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <Modal
        isVisible={displayRateUsModal}
        title={"Rate Our Service"}
        onClose={() => handleClose()}
      >
        <div className="px-6 py-2">
          <h1 style={{ fontSize: "20px", fontWeight: "500" }}>
            😁 Did you enjoy our service?
          </h1>
          <div className="mt-4 flex justify-evenly">
            {rate.map((item, index) => {
              return (
                <button
                  key={index}
                  className={`py-2 rounded-md border border-gray-500 flex justify-evenly items-center w-14 ${
                    index < ratting && "bg-yellow-200"
                  }`}
                  onClick={() => setRatting(index + 1)}
                >
                  <p
                    className={`${index < ratting && "text-white font-medium"}`}
                  >
                    {item}
                  </p>
                  {index < ratting ? (
                    <img src={Star2} alt="" width={18} />
                  ) : (
                    <img src={Star} alt="" width={18} />
                  )}
                </button>
              );
            })}
          </div>
          <h1 className="mt-4" style={{ fontSize: "20px", fontWeight: "500" }}>
            Do you have anything to add?
          </h1>
          <input
            type="text"
            placeholder="Share your thoughts here...."
            className="border-none w-full mt-1"
            style={{ borderBottom: "1px solid black" }}
            onChange={(e) => setText(e.target.value)}
            value={text}
          />
          <button
            className="w-full px-16 py-2 bg-[#1560BD] flex justify-center items-center mt-4 text-white rounded-full cursor-pointer hover:bg-[#0E4A9B]"
            onClick={submitReview}
          >
            Submit
          </button>
        </div>
      </Modal>
    </>
  );
};
export default RateUsModal;
