import { useEffect, useState } from "react";

import Modal from "../../../common/Modal/modal";
import Tip from "./Tip";
import TipModal from "./TipModal";
import PromoCode from "./PromoCode";
import TotalAmount from "./TotalAmount";
import PromoCodeModal from "./PromoCodesModal";
import { getToken } from "../../../constant/localStorage/localStorage";
import axios from "../../../constant/axios";
import { useSelector } from "react-redux";

const RightContainer = ({
  cartTotal,
  cartId,
  cartData,
  applyCoupenCode,
  removeCoupenCode,
  getPaymentMethods,
  modalControl,
  setModalControl,
  promoCodes,
}) => {
  const token = getToken();

  console.log("promoCodes => ", promoCodes);

  const [discountVouchers, setDiscountVouchers] = useState([]);

  const [displayOffersModal, setDisplayOffersModal] = useState(false);
  const [displayTipModal, setDisplayTipModal] = useState(false);

  const [customTip, setCustomTip] = useState(0);

  const [tip, setTip] = useState(null);

  const [copan, setCopan] = useState({
    value: "",
    isValid: false,
    errMsg: [],
  });

  console.log("copan => ", copan);

  const handelCheckout = () => {
    getPaymentMethods().then((res) => {
      setModalControl({
        isVisible: true,
      });
    });

    //some strip API call here
  };

  const handelCopanDiscount = (code) => {
    console.log("handelCopanDiscount => ", copan);

    let payload = {
      couponCode: code,
    };

    applyCoupenCode(payload, cartId);
  };

  const handleSetCoupen = (coupon) => {
    setCopan({
      value: coupon,
      isValid: true,
      errMsg: [],
    });
  };

  useEffect(() => {
    if (token && cartData?.coupon) {
      handleSetCoupen(cartData?.coupon);
    }
  }, [token, cartData?.coupon]);

  const getCopanData = () => {
    // const currentDate = new Date();
    // setDiscountVouchers(
    //   promoCodes.data?.filter((voucher) => {
    //     const validTillDate = new Date(voucher.validTill);
    //     return validTillDate > currentDate;
    //   })
    // );
    setDiscountVouchers(promoCodes?.data);
  };

  useEffect(() => {
    getCopanData();
  }, [promoCodes]);

  const handleRemoveCoupenCode = () => {
    if (cartId) {
      removeCoupenCode(cartId);
      setCopan({
        value: "",
        isValid: false,
        errMsg: [],
      });
    }
  };

  const handelAddCustomTipModal = () => {
    setTip("custom");
    setCustomTip(customTip);
    setDisplayTipModal(false);
  };

  return (
    <>
      <div>
        <h1 className="text-2xl font-bold mb-5">Total Amount</h1>
        <div
          className="p-6 rounded-lg"
          style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}
        >
          <TotalAmount cartTotal={cartTotal} handelCheckout={handelCheckout} />
        </div>
        <PromoCode
          setCopan={setCopan}
          cartTotal={cartTotal}
          handleRemoveCoupenCode={handleRemoveCoupenCode}
          copan={copan}
          setDisplayOffersModal={setDisplayOffersModal}
        />
      </div>
      <Modal
        title="Available offers"
        isVisible={displayOffersModal}
        width={"365px"}
        onClose={() => {
          setDisplayOffersModal(false);
          if (copan?.value !== "" && cartId !== null) {
            // handelCopanDiscount();
          }
        }}
        children={
          <PromoCodeModal
            handelCopanDiscount={handelCopanDiscount}
            discountVouchers={discountVouchers}
            setCopan={setCopan}
            setDisplayOffersModal={setDisplayOffersModal}
          />
        }
      />

      <Modal
        title="Give Tip"
        isVisible={false}
        onClose={() => setDisplayTipModal(false)}
        children={
          <TipModal
            setCustomTip={setCustomTip}
            customTip={customTip}
            handelAddCustomTipModal={handelAddCustomTipModal}
          />
        }
      />
    </>
  );
};

export default RightContainer;
