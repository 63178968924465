import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import down from "../../assets/ui_elements/down.svg";
import bell from "../../assets/ui_elements/bell.svg";
import cartIcon from "../../assets/ui_elements/cart.svg";
import edit from "../../assets/ui_elements/edit.svg";
import book from "../../assets/ui_elements/book.svg";
import info from "../../assets/ui_elements/info.svg";
import flight from "../../assets/ui_elements/airport-taxi.svg";
import ProfileModal from "./ProfileModal";
import CartModal from "./CartModal";
import { getToken } from "../../constant/localStorage/localStorage";
import { useNavigate } from "react-router-dom";
import styles from "./Navbar.module.css";

const options = [
  {
    src: edit,
    title: "Edit Profile",
    route: "",
  },
  {
    src: book,
    title: "Booking History",
    route: "",
  },
  {
    src: info,
    title: "About Company",
    route: "",
  },
];

const UserStatus = ({ CartData }) => {
  const token = getToken();

  const redirect = useNavigate();

  const userInfo = useSelector((state) => state.user.userInfo);

  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [isCartVisible, setIsCartVisible] = useState(false);

  const cart = useSelector((state) => state.cart.cartData);

  const [orders, setOrders] = useState([]);

  const handleSetOrders = (data) => {
    setOrders(data);
  };

  useEffect(() => {
    if (token && cart) {
      handleSetOrders(cart?.cartItems);
    }
  }, [token, cart]);

  const handleSubmit = () => {
    redirect("/checkout");
    setIsCartVisible(false);
  };

  return (
    <div className="flex justify-end items-center relative">
      {/* Flight Button*/}
      <button
        className="px-4 py-2"
        onMouseEnter={() => {
          setIsCartVisible(false);
          setIsOptionsVisible(false);
        }}
        onClick={() => redirect("/services/pickupService")}
      >
        <img src={flight} alt="" width={25} />
      </button>

      {/* Notifications Button*/}
      {/* <button
        className="px-4 py-2"
        onMouseEnter={() => {
          setIsCartVisible(false);
          setIsOptionsVisible(false);
        }}
      >
        <img src={bell} alt="" width={20} />
      </button> */}

      {/* Cart Button */}
      <button
        className="px-4 py-2 relative"
        onMouseEnter={() => {
          setIsCartVisible(true);
          setIsOptionsVisible(false);
        }}
      >
        <img src={cartIcon} alt="" width={30} />
        {CartData?.cartItems &&
          Array.isArray(CartData?.cartItems) &&
          CartData?.cartItems?.length > 0 && (
            <span className="text-white bg-red-500 text-center text-[12px] p-1 rounded-full absolute top-0 right-0 flex items-center justify-center w-[20px] h-[20px] ">
              {CartData?.cartItems?.length}
            </span>
          )}
      </button>

      {/* User's Profile Button */}
      <button
        onMouseEnter={() => {
          setIsCartVisible(false);
          setIsOptionsVisible(true);
        }}
        className="flex items-center"
      >
        <img
          src={userInfo?.profilePicURL}
          alt=""
          className="rounded-full object-cover w-8 h-8 mx-3"
        />
        <h1 style={{ fontWeight: "500" }} className={styles.overflowText}>
          {userInfo?.firstName} {userInfo?.lastName}
        </h1>

        <img src={down} alt="" width={20} className="mx-3 mt-1" />
      </button>

      {/* Cart Modal */}
      <CartModal
        isCartVisible={isCartVisible}
        handleSubmit={handleSubmit}
        onMouseLeave={() => {
          setIsOptionsVisible(false);
          setIsCartVisible(false);
        }}
      />

      {/* User's Profile Modal */}
      <ProfileModal
        isOptionsVisible={isOptionsVisible}
        setIsOptionsVisible={setIsOptionsVisible}
        userInfo={userInfo}
        options={options}
        onMouseLeave={() => {
          setIsOptionsVisible(false);
          setIsCartVisible(false);
        }}
      />
    </div>
  );
};
export default UserStatus;
