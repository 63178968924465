import { useState } from "react";
import Modal from "../../../common/Modal/modal";
import UploadButton from "../../upload-button/upload-button";

const Photo = ({
  imageDataUrl,
  defaultImage,
  setImageDataUrl,
  userDetails,
  isEdit,
}) => {
  const [view, setView] = useState(false);
  const handleProfile = (val) => {
    console.log("handleProfile => ", val);
    setImageDataUrl(val);
  };
  return (
    <>
      <div className="m-10 flex flex-col justify-center items-center">
        <img
          src={imageDataUrl ? imageDataUrl : defaultImage}
          alt=""
          className={`w-40 h-40 object-cover rounded-full border-8 border-blue-300 ${
            isEdit && "cursor-pointer"
          }`}
          onClick={isEdit ? () => setView(true) : () => setView(false)}
        />
        <div className="flex justify-center items-center gap-5 mt-5">
          {isEdit ? (
            <UploadButton
              accept="image/*"
              resultType="url"
              id="0f139u8h27guyebwhj"
              text="Change"
              className={
                "border px-4 py-2 rounded-full bg-blue-700 cursor-pointer border-blue-500 text-white "
              }
              onChange={handleProfile}
            />
          ) : (
            <button
              className="border px-6 py-2 rounded-full border-blue-500 text-blue-500 hover:bg-blue-100"
              style={{ fontWeight: "500" }}
              onClick={() => setView(true)}
            >
              View
            </button>
          )}
        </div>
      </div>
      <Modal
        isVisible={view}
        title={`${userDetails?.firstName.value} ${userDetails?.lastName.value}`}
        onClose={() => setView(false)}
        children={
          <div className="flex justify-center items-center">
            <img
              src={imageDataUrl ? imageDataUrl : defaultImage}
              alt=""
              className="w-full h-80 object-cover m-4 mb-0"
            />
          </div>
        }
      />
    </>
  );
};
export default Photo;
