import React from "react";
import Questionnaire from "../../components/questionaries/questionnaire";

const AddQuestionarie = () => {
  return (
    <div className="wraper flex justify-center items-center  ">
   
    <Questionnaire /> 
  </div>
  )
};

export default AddQuestionarie;
