import styles from "../../../pages/checkout/Checkout.module.css";

import discount from "../../../assets/checkout/coupon.svg";

import Validations from "../../../helper/validations/validations";
import ShowError from "../../error/ShowError";

import close from "../../../assets/ui_elements/delete.svg";

const PromoCode = ({
  copan,
  setCopan,
  setDisplayOffersModal,
  handleRemoveCoupenCode,
  getCopanCode,
}) => {
  return (
    <>
      <h1 className="font-bold text-xl mt-5 w-80">Apply promo code</h1>
      <div
        className="p-6 rounded-lg mb-10"
        style={{
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          marginTop: "20px",
        }}
      >
        <div className="promo border-b-2 border-blue-500 pb-5">
          <div className="flex items-center justify-between">
            <div
              className="flex items-center justify-between cursor-pointer w-full "
              onClick={() => setDisplayOffersModal(true)}
            >
              <input
                placeholder="Add promo code"
                id="promo"
                value={copan.value}
                className="border-2 w-full rounded-lg px-2 py-1 mx-1 ml-0 focus:outline-none font-bold text-blue-500 cursor-pointer"
              />
              {copan?.value === "" && (
                <label htmlFor="promo">
                  <img src={discount} alt="" className="mx-3 cursor-pointer" />
                </label>
              )}
            </div>
            {copan?.value !== "" && (
              <label htmlFor="promo">
                <img
                  src={close}
                  alt=""
                  className="ml-3  cursor-pointer"
                  width={20}
                  onClick={() => {
                    handleRemoveCoupenCode();
                    setCopan({
                      value: "",
                      isValid: false,
                      errMsg: [],
                    });
                  }}
                />
              </label>
            )}
          </div>
          <ShowError state={copan} />
        </div>
      </div>
    </>
  );
};
export default PromoCode;
