import React, { useEffect, useState } from "react";
import "./questionnaire.scss";
import Icon from "../../common/Icon/icon";
import axios from "../../constant/axios";
import UserAction from "../../redux/userInfo/userInfo-action";
import { connect } from "react-redux";
import Question from "./question/question";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Questionnaire = ({ customerInfo, ...props }) => {
  const redirect = useNavigate();
  const [questionData, setQuestionData] = useState();
  const [startIndex, setStartIndex] = useState(0);
  const [answer, setAnswer] = useState({});
  const [selectTime, setSelectTime] = useState([]);
  const [validation, setValidation] = useState(false);
  console.log("startIndex => ", answer);

  const getQuestions = (id) => {
    axios
      .get(`/questionnaire/getQuestionByCustomerId/${id}`)
      .then((res) => {
        if (res?.statusCode >= 200 && res?.statusCode <= 299) {
          setQuestionData(res?.data);
          console.log("getQuestions =>", res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleMultiSelect = (data, item, chacked) => {
    let obj = {
      questionId: data?.questionId,
      customerId: data["customerId"],
      answerType: data["answerType"],
    };
    let selectans = [];

    if (chacked) {
      if (answer[data?.questionId] && answer[data?.questionId].answer) {
        selectans = [...answer[data?.questionId].answer, item];
      } else {
        selectans.push(item);
      }
    } else {
      if (answer[data?.questionId]?.answer) {
        selectans = answer[data?.questionId].answer.filter((val) => {
          return val !== item;
        });
      }
    }

    obj.answer = [...selectans];
    setAnswer({
      ...answer,
      [data?.questionId]: obj,
    });
  };
  const handleFields = (data, val) => {
    let obj = {
      questionId: data?.questionId,
      customerId: data["customerId"],
      answerType: data["answerType"],
    };
    let timeObj = {
      questionId: data?.questionId,
    };
    if (data?.answerType === "time") {
      const gmtDate = new Date(val);
      timeObj.time = gmtDate;
      let filterval = selectTime.filter(
        (obj) => obj?.questionId !== data?.questionId
      );

      setSelectTime([...filterval, timeObj]);

      console.log("handleFields time  => ", gmtDate);
      const options = {
        hour12: false, // Use 24-hour format
        hour: "2-digit",
        minute: "2-digit",
        // second: "2-digit",
        // timeZoneName: "short",
      };
      // Get the local time string in the user's time zone
      const localTimeString = gmtDate.toLocaleString(undefined, options);
      obj.answer = localTimeString;
    } else if (data?.answerType === "boolean") {
      obj.answer = val;
    } else {
      obj.answer = String(val);
    }

    setAnswer({
      ...answer,
      [data?.questionId]: obj,
    });
  };

  const handlePrivous = () => {
    if (startIndex - 2 < 0) {
      return;
    }
    setStartIndex(startIndex - 2);
    setValidation(false);
  };
  const handleSubmit = () => {
    // return

    const values = Object.values(answer);
    console.log("handleSubmit => ", values);

    axios
      .post("/customers/questionnaire/submitBulkAnswer", values)
      .then((res) => {
        if (res?.statusCode >= 200 && res?.statusCode <= 299) {
          toast.success("Question Submitted !");
          redirect("/landing");
        }
      });
  };
  const handleNext = () => {
    console.log("FInal answer =>", startIndex);

    if (
      startIndex < startIndex + 2 &&
      Object.keys(answer).length < startIndex + 2 &&
      !startIndex + 2 >= questionData.length
    ) {
      toast.warning("Please fill the answer");
      setValidation(true);
      return;
    }
    if (startIndex + 2 >= questionData.length) {
      handleSubmit();
      return;
    }
    setStartIndex(startIndex + 2);
    setValidation(false);
  };

  useEffect(() => {
    if (customerInfo?.customerId) {
      getQuestions(customerInfo?.customerId);
    }
  }, [customerInfo]);
  return (
    <div className={`authForm`}>
      <Icon name="BrandLogo" />

      <div className="w-full  flex flex-col justify-between   min-h-[21rem]">
        <div>
          <div className="flex flex-col w-full mb-5">
            <div className="flex justify-between items-center">
              <div className="text-[1.25rem] font-bold leading-[2.5rem]">
                Profile Details
              </div>

              {questionData && questionData.length ? (
                <div className="text-[0.8rem] ">
                  {"Step" +
                    "(" +
                    Math.ceil((startIndex + 1) / 2) +
                    "/ " +
                    Math.ceil(questionData.length / 2) +
                    ")"}
                </div>
              ) : (
                ""
              )}
            </div>

            <div>
              <p className="text-[#666] m-0">
                Please fill the necessary details so that we can assist you
                better
              </p>
            </div>
          </div>

          <div className="flex flex-col gap-4 w-full">
            {questionData &&
              questionData.slice(startIndex, startIndex + 2).map((obj) => {
                return (
                  <>
                    <Question
                      data={obj}
                      setAnswer={setAnswer}
                      answer={answer}
                      selectTime={selectTime}
                      key={obj?.questionId}
                      AnswerTypetype={obj?.answerType}
                      handleMultiSelect={handleMultiSelect}
                      questionId={obj?.questionId}
                      handleFields={handleFields}
                    />

                    {/*
                      validation ?
                        <p className="text-red-500 text-sm">
                          Please select answer
                        </p> : ""
                        */}
                  </>
                );
              })}
          </div>
        </div>
      </div>
      <div className="flex flex-col  w-full ">
        <div className="flex gap-4 w-full mt-1 ">
          {startIndex >= 2 ? (
            <div
              className="w-6/12 text-[#1560BD] flex justify-center items-center cursor-pointer font-semibold"
              onClick={handlePrivous}
            >
              Previous
            </div>
          ) : (
            ""
          )}

          <div
            className="w-full bg-[#1560BD] text-white cursor-pointer px-3 py-2  flex justify-center items-start rounded-full"
            onClick={handleNext}
          >
            Next
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  serUserLoginInfo: (val) => dispatch(UserAction.isUserLogin(val)),
  serUserInfo: (val) => dispatch(UserAction.userInfo(val)),
});
const mapStateToProps = (state) => ({
  customerInfo: state.user.userInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(Questionnaire);
