const ActivityDetails = ({ data }) => {
  return (
    <>
      <img
        src={data?.serviceImg}
        alt=""
        className="w-full h-40 object-contain rounded-md mt-3"
      />
      <div className="flex justify-between items-center mt-4">
        <h1 className="font-bold" style={{ fontSize: "17px" }}>
          {data?.title}
        </h1>
        <p className="text-blue-500 font-bold">${data?.actualPrice}</p>
      </div>
      <p className="overflow-y-auto max-h-40 mt-4" style={{ fontSize: "13px" }}>
        {data?.description}
      </p>

      {data?.serviceIncluded && (
        <>
          <h1 className="font-bold mt-5" style={{ fontSize: "17px" }}>
            Service Includes
          </h1>
          <div className="flex justify-start items-center flex-wrap gap-3 mt-3">
            {data?.serviceIncluded.map((item, index) => {
              return (
                <p
                  key={index}
                  style={{ fontSize: "13px" }}
                  className="list-disc bg-blue-200 rounded-full px-3 py-1 text-center"
                >
                  {item}
                </p>
              );
            })}
          </div>
        </>
      )}
    </>
  );
};
export default ActivityDetails;
