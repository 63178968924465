import { useState } from "react";
import AddToCartModal from "../../Home/Activity/AddToCartModal";
import Modal from "../../../common/Modal/modal";
import useDeleteButton from "../../../helper/DeleteCountDown/useDeleteButton";
import CartAction from "../../../redux/cart/cart-action";
import { connect } from "react-redux";
import deletIcon from "../../../assets/ui_elements/trashcan.svg";
import edit from "../../../assets/ui_elements/edit3.svg";
import BookignCancelModal from "../../../pages/bookingHistory/CancelBookingModal/BookignCancelModal";

const CartCard = ({
  item,
  isLastItem,
  onRemove,
  onIncrease,
  onDecrease,
  conpact,
  DeleteFromCart,
  setServiceDeleteFlag,
  onMouseLeave,
}) => {
  const [modalControl, setModalControl] = useState({
    isVisible: false,
    data: "",
  });

  const [isDeleting, setIsDeleting] = useState(false);

  const handleServiceEdit = (data) => {
    console.log("handleServiceEdit => ", data);

    setModalControl({
      ...modalControl,
      isVisible: true,
      data: data,
    });
  };

  const handleClose = () => {
    setModalControl({
      ...modalControl,
      isVisible: false,
      data: "",
    });
  };

  const removeFromCart = () => {
    DeleteFromCart(item?.id).then((res) => {
      if (conpact) {
        setServiceDeleteFlag(false);
        onMouseLeave();
      }
    });
  };

  // const DeleteButton = useDeleteButton(
  //   "Delete",

  //   removeFromCart,
  //   `${
  //     conpact ? "px-3 py-1 w-24" : "px-4 py-2 w-[10rem]"
  //   } text-center rounded-full border text-[#1560BD] border-[#1560BD] cursor-pointer flex justify-evenly items-center`,
  //   setServiceDeleteFlag,
  //   deletIcon
  // );
  return (
    <>
      <Modal
        isVisible={modalControl?.isVisible}
        onClose={handleClose}
        children={
          <AddToCartModal
            onEdit={true}
            ServiceData={modalControl?.data}
            cartId={modalControl?.data?.serviceId}
            handleClose={handleClose}
          />
        }
      />

      <BookignCancelModal
        isVisible={isDeleting}
        handleClose={() => setIsDeleting(false)}
        title={"Warning"}
        deleteBooking={removeFromCart}
        delet={item.title}
      />

      <div
        className={`flex gap-4 border border-blue-500 px-4 py-4 rounded-xl bg-[#ecf5ff] ${
          conpact && "mt-3"
        } `}
        key={item.id}
      >
        <div className="text-center" style={{ width: conpact ? "30%" : "20%" }}>
          <img
            src={item.serviceImg}
            alt=""
            className="rounded-xl object-cover w-[6rem] h-[6rem]"
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px",
            }}
          />
        </div>

        <div
          className="flex flex-col gap-3"
          style={{ width: conpact ? "70%" : "80%" }}
        >
          <div>
            <h1
              className={`font-bold ${
                conpact ? "text-x1" : "text-2xl"
              } text-[#1560BD]`}
            >
              {item.title}
            </h1>
            <p className="font-bold ">${item?.actualPrice}</p>
          </div>

          <div className="flex w-full gap-4 items-center">
            <div
              className={`${
                conpact ? "px-3 py-1 w-24" : "px-6 py-2 w-[10rem]"
              } text-center rounded-full border text-white bg-[#1560BD] cursor-pointer flex justify-evenly items-center`}
              onClick={() => handleServiceEdit(item)}
            >
              <img src={edit} alt="" width={15} />
              <p>Edit</p>
            </div>

            {/* {DeleteButton} */}
            <button
              className={`${
                conpact ? "px-3 py-1 w-24" : "px-4 py-2 w-[10rem]"
              } text-center rounded-full border text-[#1560BD] border-[#1560BD] cursor-pointer flex justify-evenly items-center`}
              onClick={() => setIsDeleting(true)}
            >
              <img src={deletIcon} alt="" />
              <p>Delete</p>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  removeCoupenCode: (id) => dispatch(CartAction.removeCoupenCode(id)),
  DeleteFromCart: (id) => dispatch(CartAction.removeFromCart(id)),
});

const mapStateToProps = (state) => ({
  cart: state.cart.cartData,
  cartTotal: state.cart.cartTotal,
  paymentMethods: state.cart.paymentMethods,
});

export default connect(mapStateToProps, mapDispatchToProps)(CartCard);
