import AddressActionTypes from "./address_action_type";

const initialState = {
  AddressData: [],
  ActiveAddress: {
    name: "John Doe",
    email: "John@gmail.com",
    city: "New York",
    zip: 10004,
    address: "Manhattan, New York, NY, USA",
  },
};

const AddressReducer = (state = initialState, action) => {
  switch (action.type) {
    case AddressActionTypes.ADD_ADDRESS:
      return {
        ...state,
        AddressData: action.payload,
      };
    default:
      return state;
  }
};

export default AddressReducer;
