import React, { useEffect, useState } from "react";

import UserStatus from "./UserStatus";
import SearchBox from "./SearchBox";
import Logo from "./Logo";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import CartAction from "../../redux/cart/cart-action";

const Navbar = ({ cart }) => {


  const redirect = useNavigate();
  const location = useLocation();

  const [logIn, setLogin] = useState(true);

  const token = localStorage.getItem("Customer_Access_Token");

  useEffect(() => {
    if (token) {
      setLogin(false);
    }
  }, [token]);

  const LoinBTN = () => {
    return (
      <button
        onClick={() => redirect("/login")}
        className="w-40 px-16 py-3 bg-[#1560BD] flex justify-center items-center mt-2 text-white rounded-full cursor-pointer hover:bg-[#0E4A9B]"
      >
        Login
      </button>
    );
  };

  return (
    <>
      {(location.pathname === "/login" || location.pathname === "/" || location.pathname === "/addQuestion" || location.pathname === "/welcome") ? (
        <></>
      ) : (
        <div
          className=" w-full bg-white top-0 px-40 py-4 z-50 flex justify-between gap-3 items-center"
          style={{ boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.2)" }}
        >
          <Logo />
          <SearchBox />
          {logIn ? <LoinBTN /> : <UserStatus CartData={cart} />}
        </div>
      )}
    </>
  );
};


const mapDispatchToProps = (dispatch) => ({
  applyCoupenCode: (val, id) => dispatch(CartAction.applyCoupenCOde(val, id)),

});

const mapStateToProps = (state) => ({
  cart: state.cart.cartData,
  cartTotal: state.cart.cartTotal,
  paymentMethods: state.cart.paymentMethods,
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);

