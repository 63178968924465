import LoginForm from "../../components/auth/Login/LoginForm";
import BG from "../../assets/BG_login.png";
import "./loginsignUp.scss";
const Login = () => {
  return (
    <>
      <div
        className="flex justify-center items-center"
        style={{
          background: `url(${BG})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          height: "100vh",
        }}
      >
        <LoginForm />
      </div>
    </>
  );
};

export default Login;
