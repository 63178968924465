import React, { useState, useEffect } from "react";

function useDeleteButton(name, cancelFunction, className, setFlag, icon) {
  const [isCountingDown, setIsCountingDown] = useState(false);
  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    let countdownInterval;

    if (isCountingDown) {
      if (setFlag) setFlag(true);
      countdownInterval = setInterval(() => {
        if (countdown > 0) {
          setCountdown(countdown - 1);
        } else {
          clearInterval(countdownInterval);
          setIsCountingDown(false);
          if (setFlag) setFlag(false);
          cancelFunction();
        }
      }, 1000);
    }

    return () => clearInterval(countdownInterval);
  }, [isCountingDown, countdown, cancelFunction]);

  const cancelCountDown = () => {
    setIsCountingDown(false);
    if (setFlag) setFlag(false);
    setCountdown(3);
  };

  const handleCancelClick = () => {
    if (!isCountingDown) {
      setIsCountingDown(true);
    }
  };

  return (
    <>
      {countdown === 0 ? (
        <></>
      ) : (
        <div
          className={`${className} ${
            isCountingDown && "bg-red-300 text-red-800 border border-red-800"
          }`}
          onClick={isCountingDown ? cancelCountDown : handleCancelClick}
        >
          {icon && <img src={icon} alt="" width={15} />}
          {isCountingDown ? <p>{countdown}</p> : <button>{name}</button>}
        </div>
      )}
    </>
  );
}

export default useDeleteButton;
