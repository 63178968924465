import { useNavigate } from "react-router-dom";
import ActivityCards from "./ActivityCards";

const Activity = ({ activities }) => {
  const redirect = useNavigate();
  return (
    <div className="mx-40">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Popular</h1>
        <p
          className="text-sm underline cursor-pointer"
          style={{ fontWeight: "500" }}
          onClick={() => redirect("/services")}
        >
          View all
        </p>
      </div>
      <ActivityCards cardsData={activities} />
    </div>
  );
};
export default Activity;
