import activity from "../../assets/ui_elements/emptyActivity.svg";
import styles from "./Ripple.module.scss";

const EmptyCart = ({ title, description, conpact = false }) => {
  return (
    <div className={conpact ? styles.emptyCom : styles.empty}>
      <div
        className={`bg-blue-800 flex justify-center rounded-full ${
          conpact ? styles.stepCom : styles.step
        }`}
      >
        <img src={activity} alt="" width={conpact && 15} />
      </div>
      <h1>{title}</h1>
      <p>{description}</p>
    </div>
  );
};
export default EmptyCart;
