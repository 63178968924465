import { useNavigate } from "react-router-dom";

const SubCategoryCard = ({ data }) => {
  const styles = {
    overflow: "hidden",
    "text-overflow": "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": "3",
    "-webkit-box-orient": "vertical",
  };

  const redirect = useNavigate();

  const serviceClickHandler = (id) => {
    redirect(`/category/${id}/service?isSubCategory=true`);
  };

  return (
    <>
      {data?.map((item, index) => {
        return (
          <div
            key={index}
            className="w-96 h-96 relative border bg-black rounded-lg cursor-pointer"
            style={{
              background: `url(${item.categoryIcon})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className="absolute inset-0 flex flex-col items-center justify-end">
              <h1 className="relative flex justify-between items-center text-center p-2 px-5 w-full text-white bg-gray-400 bg-opacity-50 backdrop-blur-lg rounded-b-lg">
                <p
                  className="text-left"
                  style={{
                    ...styles,
                    fontSize: "13px",
                    width: "70%",
                    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)",
                  }}
                >
                  {item.title}
                </p>

                <button
                  className="bg-blue-500 px-3 py-1 rounded-md"
                  style={{ fontSize: "13px" }}
                  onClick={() => serviceClickHandler(item?.id)}
                >
                  Know More
                </button>
              </h1>
            </div>
          </div>
        );
      })}
    </>
  );
};
export default SubCategoryCard;
