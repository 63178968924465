import React, { useState, useEffect } from "react";
import arow from "../../../assets/ui_elements/rightImgButton.svg";

const ImageSlider = ({ images, interval = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToIndex = (index) => {
    setCurrentIndex(index);
  };

  const showNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const showPreviousImage = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  useEffect(() => {
    const timer = setInterval(() => {
      showNextImage();
    }, interval);

    return () => clearInterval(timer);
    // eslint-disable-next-line
  }, [images, interval]);

  return (
    <div
      className="relative w-full flex justify-center items-center"
      style={{ height: "50vh" }}
    >
      {images && images.length === 0 && (
        <>
          <div
            className="w-14 h-14 rounded-full border-white-200 border-t-blue-500 animate-spin"
            style={{ borderWidth: "8px" }}
          ></div>
        </>
      )}
      {images?.map((image, index) => (
        <img
          key={index}
          src={image?.imgURL}
          alt={`Slider ${index}`}
          className={`absolute object-cover rounded-lg shadow-md transition-opacity duration-500 ${
            index === currentIndex ? "opacity-100" : "opacity-0"
          }`}
          style={{ width: "750px", height: "280px", zIndex: "10" }}
        />
      ))}

      {images && images.length !== 0 && (
        <>
          <img
            src={
              images[(currentIndex - 1 + images.length) % images.length]?.imgURL
            }
            alt={`Slider ${currentIndex + 1}`}
            className="absolute object-cover transition-opacity duration-1000 opacity-100 rounded-lg z-10 shadow-md"
            style={{
              width: "500px",
              height: "200px",
              left: "11%",
              zIndex: "1",
            }}
          />

          {/* Next Image */}
          <img
            src={images[(currentIndex + 1) % images.length]?.imgURL}
            alt={`Slider ${currentIndex - 1}`}
            className="absolute object-cover transition-opacity duration-1000 opacity-100 rounded-lg z-10 shadow-md"
            style={{
              width: "500px",
              height: "200px",
              right: "11%",
              zIndex: "1",
            }}
          />
          {/* Left Button */}
          <img
            src={arow}
            alt=""
            className="absolute object-cover px-4 py-3 border shadow-lg rounded-full cursor-pointer bg-white"
            style={{
              left: "9%",
              zIndex: "2",
            }}
            onClick={showPreviousImage}
          />
          {/* Right Button */}
          <img
            src={arow}
            alt=""
            className="absolute object-cover px-4 py-3 border shadow-lg rounded-full cursor-pointer bg-white"
            style={{
              right: "9%",
              zIndex: "2",
              transform: "scaleX(-1)",
            }}
            onClick={showNextImage}
          />
          <div className="absolute bottom-7 left-1/2 transform -translate-x-1/2 flex space-x-2 z-10">
            {images.map((_, index) => (
              <button
                key={index}
                className={`h-1 rounded-full ${
                  index === currentIndex ? "bg-blue-500 w-3" : "bg-blue-200 w-1"
                }`}
                onClick={() => goToIndex(index)}
              ></button>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ImageSlider;
