import React, { useEffect, useState } from "react";
import Modal from "../../../common/Modal/modal";
import { Radio } from "@material-tailwind/react";
import { toast } from "react-toastify";
import axios from "../../../constant/axios";
import Icon from "../../../common/Icon/icon";
import PaymentSuccess from "../../../assets/PaymentSuccess.png";
import PaymentFailed from "../../../assets/PaymentFailed.png";
import { useNavigate } from "react-router-dom";
import EmptyCart from "../../../components/checkout/EmptyCart";
import PaymentStatus from "./PaymentStatus";
import { capitalizeString } from "../../../common/StringOpration/capitalizeString";
import CardLogo from "./PaymentCardLogo/CardLogo";
import delet from "../../../assets/ui_elements/trashcan.svg";

const CardModal = ({
  isVisible,
  handleClose,
  paymentMethods,
  getPaymentMethods,
  handlePayNow,
  paymentStatus,
  cardSelect,
  cardError,
  setCardSelect,
  setCardError,
  cartTotal,
}) => {
  const [visibilityState, setVisibilityState] = useState(
    document.visibilityState
  );
  const handleVisibilityChange = () => {
    setVisibilityState(document.visibilityState);
  };

  console.log("paymentStatus =>", paymentStatus);

  useEffect(() => {
    window.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      window.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    if (visibilityState === "visible") {
      // Call the API
      getPaymentMethods();
    }
  }, [visibilityState]);

  const addStripeCard = () => {
    axios
      .get("/customers/stripe/getCheckoutUrlForSaveCard")
      .then((res) => {
        if (res?.statusCode >= 200 && res?.statusCode <= 299) {
          console.log("addStripeCard => ", res?.data?.url);
          window.open(res?.data?.url);
        }
      })
      .catch((err) => {});
  };

  console.log("paymentMethods => ", paymentMethods);
  return (
    <Modal
      isVisible={isVisible}
      onClose={handleClose}
      width={"480px"}
      styles={{ borderRadius: "2rem" }}
    >
      {!paymentStatus && (
        <div className=" px-8 py-4 flex justify-between items-center">
          <div className="text-xl font-semibold">Select an option to pay</div>
          <div className="text-xl font-semibold">
            ${cartTotal?.totalServicePayable}
          </div>
        </div>
      )}
      {!paymentMethods.length ? (
        <div className="px-8 flex flex-col gap-4">
          <div className="flex justify-center ">
            <EmptyCart title="There are no Card Available" description="" />
          </div>

          <div className="px-10">
            <button
              className=" rounded-full border border-[#1560BD] shadow-lg text-[#1560BD] font-semibold mt-5 py-2 w-full "
              onClick={addStripeCard}
            >
              + Add New Card
            </button>
            <button
              className="bg-blue-600 rounded-full shadow-lg text-white font-semibold mt-5 py-2 w-full hover:bg-blue-700"
              onClick={handlePayNow}
            >
              Pay Now
            </button>
          </div>
        </div>
      ) : (
        <div className="px-8 ">
          {paymentStatus ? (
            <PaymentStatus status={paymentStatus} />
          ) : (
            <>
              <PaymentCards
                cardData={paymentMethods}
                cardSelect={cardSelect}
                getPaymentMethods={getPaymentMethods}
                setCardSelect={setCardSelect}
                cardError={cardError}
                setCardError={setCardError}
              />

              <div className="px-10">
                <button
                  className=" rounded-full border border-[#1560BD] shadow-lg text-[#1560BD] font-semibold mt-5 py-2 w-full "
                  onClick={addStripeCard}
                >
                  + Add New Card
                </button>
                <button
                  className="bg-blue-600 rounded-full shadow-lg text-white font-semibold mt-5 py-2 w-full hover:bg-blue-700"
                  onClick={handlePayNow}
                >
                  Pay Now
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </Modal>
  );
};

const PaymentCards = ({
  cardData,
  cardSelect,
  setCardSelect,
  getPaymentMethods,
  cardError,
  setCardError,
}) => {
  // const [cardSelect, setCardSelect] = useState()

  console.log("card Data => ", cardData);

  useEffect(() => {
    // handleSelectCard(cardData[0])
  }, []);

  const handleSelectCard = (data, e) => {
    setCardError(false);
    setCardSelect(data);
  };
  const handleDeletCard = (data) => {
    console.log("handleDeletCard => ", data);
    axios
      .delete(`/customers/stripe/deletePaymentMethod/${data?.paymentMethodId}`)
      .then((res) => {
        if (res?.statusCode >= 200 && res?.statusCode <= 299) {
          getPaymentMethods();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div
      className={`border-2 rounded-xl p-4 max-h-[32vh] overflow-auto ${
        cardError
          ? "border-red-500 bg-red-200"
          : "border-[#99c6ff] bg-[#ecf5ff]"
      }`}
    >
      <div className="font-bold">Cards</div>
      <div className="flex flex-col gap-4">
        {cardData &&
          cardData.map((obj, idx) => {
            return (
              <div key={obj?.id} className="flex items-center  w-full">
                <Radio
                  name="type"
                  onChange={() => handleSelectCard(obj)}
                  color="blue"
                  defaultChecked={false}
                />
                <div className="flex gap-3 items-center  w-9/12">
                  <div className="w-[50px]">
                    <CardLogo logo={obj?.card?.brand} />
                  </div>
                  <div className="flex justify-between w-full">
                    <div className="flex flex-col gap-1">
                      <div className="text-[1.12rem] font-semibold">
                        {capitalizeString(obj?.card?.brand)}
                      </div>
                      <div className="text-[0.8rem]">
                        ************{obj?.card?.last4}
                      </div>
                    </div>

                    {cardSelect?.id === obj?.id ? (
                      <div
                        className="px-4 w-6/12  h-[40px] text-center flex justify-evenly items-center py-1 cursor-pointer text-[#1560BD] border-[2px] border-[#1560BD] rounded-full font-semibold"
                        onClick={() => handleDeletCard(obj)}
                      >
                        <img src={delet} />
                        <p>Delete</p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default CardModal;
