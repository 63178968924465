import { connect } from "react-redux";
import LeftContainer from "../../components/checkout/LeftContainer/LeftContainer";
import RightContainer from "../../components/checkout/RightContainer/RightContainer";
import CartAction from "../../redux/cart/cart-action";

import CardModal from "./PaymentCard/CardModal";
import { useEffect, useState } from "react";
import { getToken } from "../../constant/localStorage/localStorage";
import EmptyCart from "../../components/checkout/EmptyCart";

import StripeAddCard from "./AddPaymentCard/AddCard";
import { toast } from "react-toastify";

const Checkout = ({
  cart,
  getCart,
  cartTotal,
  applyCoupenCode,
  removeCoupenCode,
  getPaymentMethods,
  paymentMethods,
  payNow,
  getCopanCode,
  promoCodes,
}) => {
  const [modalControl, setModalControl] = useState({
    isVisible: false,
    data: "",
  });

  const [paymentStatus, setPaymentStatus] = useState("");
  const [cardSelect, setCardSelect] = useState();

  const [cardError, setCardError] = useState(false);

  const [orders, setOrders] = useState([]);

  console.log("orders =>", orders);

  const handleClose = () => {
    setModalControl({
      isVisible: false,
      data: "",
    });
    // window.location.reload();
  };

  const handlePayNow = () => {
    if (!cardSelect) {
      setCardError(true);
      toast.error("Select Card First");
      return;
    }
    let payload = {
      cartId: cart?.id,
      modeOfPayment: "CARD",
      stripePaymentMethodId: cardSelect?.paymentMethodId,
    };
    payNow(payload).then((res) => {
      setPaymentStatus(res?.paymentStatus);
    });
  };

  const token = getToken();

  useEffect(() => {
    if (token ) {
      setOrders(cart?.cartItems);
      getCopanCode();
    }
  }, [token, cart]);

  return (
    <>
      <CardModal
        cartTotal={cartTotal}
        isVisible={modalControl?.isVisible}
        handleClose={handleClose}
        paymentMethods={paymentMethods}
        cardSelect={cardSelect}
        setCardSelect={setCardSelect}
        getPaymentMethods={getPaymentMethods}
        paymentStatus={paymentStatus}
        handlePayNow={handlePayNow}
        cardError={cardError}
        setCardError={setCardError}
      />
      <StripeAddCard isVisible={false} handleClose={handleClose} />

      <div className="flex flex-row justify-center gap-10 mt-8">
         {!orders  || orders?.length === 0? (
          <div className="wrapper w-[100vw] h-[80vh] flex items-center justify-center">
            <EmptyCart
              title="There are no Activities Available"
              description="Available activities will show here"
            />
          </div>
        ) : (
          <>
            <LeftContainer />
            <RightContainer
              cartTotal={cartTotal}
              cartId={cart?.id}
              cartData={cart}
              applyCoupenCode={applyCoupenCode}
              removeCoupenCode={removeCoupenCode}
              setModalControl={setModalControl}
              modalControl={modalControl}
              getPaymentMethods={getPaymentMethods}
              promoCodes={promoCodes}
            />
          </>
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  applyCoupenCode: (val, id) => dispatch(CartAction.applyCoupenCOde(val, id)),
  removeCoupenCode: (id) => dispatch(CartAction.removeCoupenCode(id)),
  getPaymentMethods: () => dispatch(CartAction.getPaymentMethods()),
  payNow: (val) => dispatch(CartAction.payNow(val)),
  getCopanCode: () => dispatch(CartAction.getCopanCode()),
  getCart: () => dispatch(CartAction.getCart()),
});
const mapStateToProps = (state) => ({
  cart: state.cart.cartData,
  cartTotal: state.cart.cartTotal,
  paymentMethods: state.cart.paymentMethods,
  promoCodes: state.cart.promoCodes,
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
