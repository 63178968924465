const TipModal = ({ setCustomTip, customTip, handelAddCustomTipModal }) => {
  return (
    <div className="flex flex-col justify-between">
      <label htmlFor="tip" className="mx-3">
        Plese Enter Tip Amount $
      </label>
      <input
        id="tip"
        type="number"
        onChange={(e) => setCustomTip(e.target.value)}
        value={customTip}
        className="box-border border-2 rounded-lg px-3 py-1 mx-3 my-3 focus:outline-none"
      />

      <button
        className="bg-blue-200 px-4 py-1 mx-3 rounded-md border border-blue-500"
        onClick={handelAddCustomTipModal}
      >
        Add Tip
      </button>
    </div>
  );
};
export default TipModal;
