import CartActionTypes from "./cart_action_type";
import axios from "../../constant/axios";
import { toast } from "react-toastify";
import screenLoaderAction from "../screenLoader/action";
import { getToken } from "../../constant/localStorage/localStorage";

const CartAction = {
  setCartDetail: (payload) => ({
    type: CartActionTypes.CART_DETAIL,
    payload,
  }),
  setCartTotal: (payload) => ({
    type: CartActionTypes.CART_TOTAL,
    payload,
  }),
  setPromoCodes: (payload) => ({
    type: CartActionTypes.PROMO,
    payload,
  }),
  setPaymentCards: (payload) => ({
    type: CartActionTypes.PAYMNET_CARD,
    payload,
  }),

  Cart_calculate_price: (id) => (dispatch) => {
    // Change Add address Base Url Hear

    return new Promise((resolve, reject) => {
      let token = getToken();

      if (token) {
        // For Screen Loading
        dispatch(screenLoaderAction.isLoading(false));
        axios
          // Change Add address Base Url Hear
          .get(`/customers/cart/calculate-price/${id}`)
          .then((res) => {
            if (res?.statusCode >= 200 && res?.statusCode <= 299) {
              // For Storing Address Data in Redux
              dispatch(CartAction.setCartTotal(res?.data));

              resolve(res?.data);

              dispatch(screenLoaderAction.isLoading(false));
            } else {
              dispatch(screenLoaderAction.isLoading(false));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  },

  getCopanCode: () => (dispatch) => {
    return new Promise((resolve, reject) => {
      let token = getToken();

      if (token) {
        // For Screen Loading
        dispatch(screenLoaderAction.isLoading(false));
        let query = {
          params: {
            status : "active"
          }
        }
        axios
          // Change Add address Base Url Hear
          .get("/promo" , query)
          .then((res) => {
            if (res?.statusCode >= 200 && res?.statusCode <= 299) {
              // For Storing Address Data in Redux
              console.log("lol");
              dispatch(CartAction.setPromoCodes(res?.data));

              resolve(res?.data);

              dispatch(screenLoaderAction.isLoading(false));
            } else {
              dispatch(screenLoaderAction.isLoading(false));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  },

  applyCoupenCOde: (payload, id) => (dispatch) => {
    // Change Add address Base Url Hear

    return new Promise((resolve, reject) => {
      let token = getToken();

      if (token) {
        // For Screen Loading
        dispatch(screenLoaderAction.isLoading(false));
        axios
          // Change Add address Base Url Hear
          .post(`/customers/cart/apply-coupon/${id}`, payload)
          .then((res) => {
            if (res?.statusCode >= 200 && res?.statusCode <= 299) {
              // For Storing Address Data in Redux
              dispatch(CartAction.Cart_calculate_price(id));

              resolve(res?.data);

              dispatch(screenLoaderAction.isLoading(false));
            } else {
              dispatch(screenLoaderAction.isLoading(false));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  },

  removeCoupenCode: (id) => (dispatch) => {
    // Change Add address Base Url Hear

    return new Promise((resolve, reject) => {
      let token = getToken();

      if (token) {
        // For Screen Loading
        dispatch(screenLoaderAction.isLoading(false));
        axios
          // Change Add address Base Url Hear
          .put(`/customers/cart/remove-applied-coupon/${id}`)
          .then((res) => {
            if (res?.statusCode >= 200 && res?.statusCode <= 299) {
              // For Storing Address Data in Redux
              dispatch(CartAction.Cart_calculate_price(id));

              resolve(res?.data);

              dispatch(screenLoaderAction.isLoading(false));
            } else {
              dispatch(screenLoaderAction.isLoading(false));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  },

  getPaymentMethods: () => (dispatch) => {
    // Change Add address Base Url Hear

    return new Promise((resolve, reject) => {
      let token = getToken();

      if (token) {
        // For Screen Loading
        dispatch(screenLoaderAction.isLoading(false));
        axios
          // Change Add address Base Url Hear
          .get("/customers/stripe/getPaymentMethods")
          .then((res) => {
            if (res?.statusCode >= 200 && res?.statusCode <= 299) {
              // For Storing Address Data in Redux
              dispatch(CartAction.setPaymentCards(res?.data));

              resolve(res?.data);

              dispatch(screenLoaderAction.isLoading(false));
            } else {
              dispatch(screenLoaderAction.isLoading(false));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  },

  payNow: (payload) => (dispatch) => {
    // Change Add address Base Url Hear

    return new Promise((resolve, reject) => {
      let token = getToken();

      if (token) {
        // For Screen Loading
        dispatch(screenLoaderAction.isLoading(false));
        axios
          // Change Add address Base Url Hear
          .post("/customers/bookings/", payload)
          .then((res) => {
            if (res?.statusCode >= 200 && res?.statusCode <= 299) {
              // For Storing Address Data in Redux
              toast.success(res?.message);
              dispatch(CartAction.getCart());

              resolve(res?.data);

              dispatch(screenLoaderAction.isLoading(false));
            } else {
              dispatch(screenLoaderAction.isLoading(false));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  },

  getCart: () => (dispatch) => {
    // Change Add address Base Url Hear

    return new Promise((resolve, reject) => {
      let token = getToken();

      if (token) {
        // For Screen Loading
        dispatch(screenLoaderAction.isLoading(false));
        axios
          // Change Add address Base Url Hear
          .get("/customers/cart/getCustomerCart")
          .then((res) => {
            if (res?.statusCode >= 200 && res?.statusCode <= 299) {
              // For Storing Address Data in Redux
              dispatch(CartAction.setCartDetail(res?.data));
              if (res?.data?.cartItems) {
                dispatch(CartAction.Cart_calculate_price(res?.data?.id));
              }

              resolve(res?.data);

              dispatch(screenLoaderAction.isLoading(false));
            } else {
              dispatch(screenLoaderAction.isLoading(false));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  },
  removeFromCart: (id) => (dispatch) => {
    // Change Add address Base Url Hear

    return new Promise((resolve, reject) => {
      let token = getToken();

      if (token) {
        // For Screen Loading
        dispatch(screenLoaderAction.isLoading(false));
        axios
          // Change Add address Base Url Hear
          .delete(`/customers/cart-item/deleteCartItem/${id}`)
          .then((res) => {
            if (res?.statusCode >= 200 && res?.statusCode <= 299) {
              // For Storing Address Data in Redux
              dispatch(CartAction.getCart());

              toast.success("Item Removed from Cart!");
              resolve(res?.data);

              dispatch(screenLoaderAction.isLoading(false));
            } else {
              dispatch(screenLoaderAction.isLoading(false));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  },
};

export default CartAction;
