import React, { useEffect } from "react";
import Input from "../../../common/Input/input";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import { MobileTimePicker } from "@mui/x-date-pickers";
import { capitalizeString } from "../../../common/StringOpration/capitalizeString";

const Question = ({
  data,
  AnswerTypetype,
  questionId,
  answer,
  handleMultiSelect,
  handleFields,
  selectTime,
}) => {
  console.log("Question => ", answer[questionId]?.answer);

  if (AnswerTypetype === "multiple-selection") {
    return (
      <div className="w-full">
        <label>{capitalizeString(data?.questionText)}</label>
        {data?.answerOption &&
          data?.answerOption.map((item) => {
            return (
              <div className="flex gap-3 items-center">
                <input
                  type="checkbox"
                  checked={
                    answer &&
                    answer[questionId]?.answer &&
                    answer[questionId].answer.includes(item)
                  }
                  onChange={(e) =>
                    handleMultiSelect(data, item, e.target.checked)
                  }
                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded "
                />
                <span>{capitalizeString(item)}</span>
              </div>
            );
          })}
      </div>
    );
  }

  if (AnswerTypetype === "single-selection") {
    return (
      <div className=" w-full">
        <label>{capitalizeString(data?.questionText)}</label>
        {data?.answerOption &&
          data?.answerOption.map((item, idx) => {
            return (
              <div key={item} className="flex items-center  gap-4">
                <input
                  key={item}
                  id={`country-option-${idx + 1}`}
                  type="radio"
                  onChange={() => handleFields(data, item)}
                  checked={answer[questionId]?.answer === item ? true : false}
                  className="w-4 h-4 border-gray-300 "
                />
                <label>{capitalizeString(item)}</label>
              </div>
            );
          })}
      </div>
    );
  }

  if (AnswerTypetype === "text") {
    return (
      <div className=" w-full flex flex-col gap-4">
        <label>{capitalizeString(data?.questionText)}</label>
        <input
          type="text"
          id="voice-search"
          onChange={(e) => handleFields(data, e.target.value)}
          value={answer && answer[questionId]?.answer}
          className="bg-[#F3F3F3] border px-4 py-2 border-none outline-none rounded-lg"
          required
        />
      </div>
    );
  }

  if (AnswerTypetype === "numeric") {
    return (
      <div className=" w-full flex flex-col gap-4">
        <label>{capitalizeString(data?.questionText)}</label>
        <input
          type="number"
          id="voice-search"
          onChange={(e) => handleFields(data, e.target.value)}
          value={answer && answer[questionId]?.answer}
          className="bg-[#F3F3F3] border px-4 py-2 border-none outline-none rounded-lg"
          onWheel={(e) => e.target.blur()}
          required
        />
      </div>
    );
  }

  if (AnswerTypetype === "boolean") {
    return (
      <div className="flex flex-col gap-2">
        <label>{capitalizeString(data?.questionText)}</label>
        <div className="flex items-center  gap-4">
          <input
            id="country-option-3"
            type="radio"
            onChange={() => handleFields(data, true)}
            checked={answer[questionId]?.answer}
            className="w-4 h-4 border-gray-300 "
          />
          <label>Yes</label>
        </div>
        <div className="flex items-center  gap-4">
          <input
            id="country-option-4"
            type="radio"
            onChange={() => handleFields(data, false)}
            checked={!answer[questionId]?.answer}
            className="w-4 h-4 border-gray-300 "
          />
          <label>No</label>
        </div>
      </div>
    );
  }

  if (AnswerTypetype === "date") {
    let date = new Date(answer[questionId]?.answer);
    console.log("Date String => ", date);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    console.log("formattedDate => ", formattedDate);

    return (
      <div className="flex flex-col gap-2 w-full">
        <label>{capitalizeString(data?.questionText)}</label>

        <div className="relative max-w-sm w-full ">
          <DatePicker
            placeholder="Select a date"
            className="bg-[#F3F3F3]  w-full border px-4 py-2 border-none outline-none rounded-lg"
            onChange={(val) => handleFields(data, val)}
            value={formattedDate !== "NaN-NaN-NaN" ? formattedDate : ""}
          />
        </div>
      </div>
    );
  }

  if (AnswerTypetype === "time") {
    console.log("selectTime => ", selectTime);

    let time = selectTime.filter((obj) => obj?.questionId === data?.questionId);

    // let inputTime = answer[questionId]?.answer
    // let formattedTime = {}
    // if (answer[questionId]?.answer) {

    //   console.log("Time Select => ", answer[questionId])
    //   const timeParts = inputTime.split(':');
    //   const hours = parseInt(timeParts[0], 10);
    //   const minutes = parseInt(timeParts[1], 10);
    //   const jsDate = new Date();
    //   jsDate.setHours(hours, minutes);

    //   // Format the Date object as "h:mm a"
    //   formattedTime = jsDate.toLocaleString('en-US', {
    //     hour: 'numeric',
    //     minute: 'numeric',
    //     hour12: true,
    //   });
    // }

    return (
      <div className="flex flex-col gap-2 w-full">
        <label>{capitalizeString(data?.questionText)}</label>

        <div className="relative max-w-sm w-full ">
          <MobileTimePicker
            value={time[0]?.time ? dayjs(time[0]?.time) : ""}
            onChange={(val) => handleFields(data, val)}
          />
        </div>
      </div>
    );
  }
  return "";
};

export default Question;
