import React, { useState } from "react";
import pass from "../../assets/ui_elements/pass.svg";
import passClose from "../../assets/ui_elements/passClose.svg";

const Input = ({
  isValied,
  label,
  value,
  name,
  onChange,
  optional,
  checkValidation,
  className,
  placeholder,
  type,
}) => {
  const [passVisible, setPassVisible] = useState(false);
  return (
    <>
      <div className="flex flex-col ">
        {label ? (
          <label className="mb-1 text-sm">
            {label} {optional ? "(Optional)" : ""}
          </label>
        ) : (
          ""
        )}
        {type === "password" ? (
          <div
            className={`border flex justify-between items-center ${
              !optional && checkValidation && !isValied
                ? "border-red-500"
                : "border-black"
            } rounded ${className && className}`}
          >
            <input
              className="bg-transparent px-3 py-1 w-1/2 border-none outline-none focus:ring-0 focus:border-transparent appearance-none"
              onChange={onChange}
              type={passVisible ? "text" : "password"}
              name={name}
              value={value}
              placeholder={placeholder}
              style={{
                backgroundClip: "content-box",
                WebkitBoxShadow: "0 0 0 1000px transparent inset",
              }}
            />
            {passVisible ? (
              <img
                src={pass}
                alt=""
                width={30}
                className="mr-3 cursor-pointer"
                style={{ marginTop: "2px" }}
                onClick={() => setPassVisible(false)}
              />
            ) : (
              <img
                src={passClose}
                alt=""
                width={30}
                className="mr-3 cursor-pointer"
                onClick={() => setPassVisible(true)}
              />
            )}
          </div>
        ) : (
          <input
            className={`outline-none px-3 py-1 border ${
              !optional && checkValidation && !isValied
                ? "border-red-500"
                : "border-black"
            } rounded ${className && className}`}
            onChange={onChange}
            name={name}
            value={value}
            placeholder={placeholder}
          />
        )}
      </div>
    </>
  );
};

export default Input;
