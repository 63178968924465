import React, { useState } from "react";
import chats from "../../assets/ui_elements/chat.svg";
import chat from "../../assets/ui_elements/chat2.svg";
import RightDrawerModal from "../../components/Drawer/RightDrawerModal";
import { Box, Skeleton } from "@mui/material";
import { capitalizeString } from "../../common/StringOpration/capitalizeString";

import axios from "../../constant/axios";

const Providers = () => {
  const [agents, setAgents] = useState([]);

  const styles = {
    overflow: "hidden",
    "text-overflow": "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": "1",
    "-webkit-box-orient": "vertical",
  };

  const getAllAgentsData = () => {
    axios
      .get(`support-agents?isBlocked=false`)
      .then((res) => {
        console.log(res.data.supportAgents);
        setAgents(res.data.supportAgents);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useState(() => {
    getAllAgentsData();
  }, []);

  const contactViaWhatsApp = (code, number) => {
    let phoneNumber;
    // phoneNumber = code.substring(1) + number;
    phoneNumber = "15203285590"; // Test Mode
    const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}`;
    window.open(whatsappURL, "_blank");
  };

  return (
    <div>
      <h1 className="font-medium text-xl">Chat with Agents</h1>
      <div
        className="flex  flex-wrap gap-9 my-5 overflow-y-scroll"
        style={{ height: "75vh" }}
      >
        {agents?.length === 0 && (
          <>
            {Array.from({ length: 6 }).map((_, index) => (
              <Box key={index}>
                <Skeleton variant="rounded" width={160} height={160} />
              </Box>
            ))}
          </>
        )}
        {agents?.map((item, index) => {
          return (
            <div
              key={index}
              className="w-40 h-40 relative border bg-black rounded-lg "
              style={{
                background: `url(${item.profilePicURL})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="absolute inset-0 flex flex-col items-center justify-end">
                <h1
                  className="relative text-center p-2 w-full text-white bg-gray-400 bg-opacity-50 backdrop-blur-lg rounded-b-lg flex justify-between items-center cursor-pointer"
                  onClick={() =>
                    contactViaWhatsApp(item.countryCode, item.phoneNo)
                  }
                >
                  <span style={{ ...styles }}>
                    {capitalizeString(item?.name)}
                  </span>
                  <img src={chat} alt="" width={20} />
                </h1>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const Chat = () => {
  const [status, setStatus] = useState(false);
  return (
    <>
      <RightDrawerModal
        isOpen={status}
        onClose={() => setStatus(false)}
        children={<Providers />}
      />

      <div
        className="flex justify-center items-center w-14 h-14 bg-blue-800 rounded-full fixed bottom-10 right-10 cursor-pointer"
        onClick={() => setStatus(true)}
      >
        <img src={chats} alt="" width={30} />
      </div>
    </>
  );
};

export default Chat;
