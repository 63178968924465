import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyCNN05N1YmoUZngbTz9Jg9rcsUd-WugI-Y",
    authDomain: "the-excursionist.firebaseapp.com",
    projectId: "the-excursionist",
    storageBucket: "the-excursionist.appspot.com",
    messagingSenderId: "838876497427",
    appId: "1:838876497427:web:8aaba29a7e66d641b0e1e6",
    measurementId: "G-N95PD8Z21Q",
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(app);
