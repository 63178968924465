import { useEffect, useState } from "react";

import logo from "../../../assets/Logo.svg";
import axios from "../../../constant/axios";

import CalendarInput from "./CalendarInput";
import SkeletonLoad from "./SkeletonLoad";
import ActivityDetails from "./ActivityDetails";
import ActivityButton from "./ActivityButton";
import FormInput from "./FormInput";
import { connect } from "react-redux";
import CartAction from "../../../redux/cart/cart-action";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function convertISOTimeToLocaleTime(isoTime, reverse = false) {
  if (!reverse) {
    const [hours, minutes] = isoTime.split(":").map(Number);
    const date = new Date();
    date.setUTCHours(hours, minutes);

    const options = {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const formattedTime = new Intl.DateTimeFormat(undefined, options).format(
      date
    );
    return formattedTime;
  } else {
    const [year, month, day] = isoTime.split("-");
    const isoDate = new Date(Date.UTC(year, month - 1, day)).toISOString();
    return isoDate;
  }
}

function formatDateTime(input) {
  const { date, time } = input;

  const dateObj = new Date(date);

  const options = { month: "long", day: "numeric" };
  const formattedDate = dateObj.toLocaleDateString("en-US", options);

  const weekdayAbbreviation = dateObj.toLocaleDateString("en-US", {
    weekday: "short",
  });

  const formattedDateTime = `${formattedDate} (${weekdayAbbreviation}), ${convertISOTimeToLocaleTime(
    time
  )}`;

  return formattedDateTime;
}

const AddToCartModal = ({
  cartId,
  handleClose,
  getCart,
  onEdit,
  ServiceData,
  cartItems,
  cardData,
  display = false,
}) => {
  const [data, setData] = useState();

  const [selectedDate, setSelectedDate] = useState(null);
  const [date, setDate] = useState(null);
  const [time, setTime] = useState(null);

  const [valid, setValid] = useState(false);

  const [showCalender, setShowCalender] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const [scheduled, setScheduled] = useState(null);
  const [pickupDetails, setPickupDetails] = useState(null);

  const [bookingSlotDateTime, setBookingSlotDateTime] = useState(null);

  const token = localStorage.getItem("Customer_Access_Token");

  const redirect = useNavigate();

  console.log(selectedDate);

  useEffect(() => {
    if (onEdit) {
      setTime(bookingSlotDateTime?.bookingSlotTime);
      setDate(bookingSlotDateTime?.bookingSlotDate);
    }
  }, [onEdit]);

  useEffect(() => {
    const getServiceData = () => {
      // Getting All Services Data
      axios
        .get(`/services/${cartId}`)
        .then((res) => {
          setData(res.data);
          if (onEdit) {
            if (
              !ServiceData.pickupDetail ||
              ServiceData.pickupDetail === null
            ) {
              setBookingSlotDateTime(ServiceData);
              setPickupDetails(ServiceData);
            } else {
              setPickupDetails(ServiceData.pickupDetail);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    if (!display) {
      getServiceData();
    } else {
      setData(cardData);
    }
    // eslint-disable-next-line
  }, []);

  console.log(ServiceData);

  const editService = (payload) => {
    let service_cartItem = [];

    if (cartItems && Array.isArray(cartItems)) {
      service_cartItem = cartItems.filter((obj) => obj?.serviceId === data?.id);
    }

    if (!display) {
      axios
        .put(
          `/customers/cart-item/updateCartItem/${service_cartItem[0]?.id}`,
          payload
        )
        .then((res) => {
          toast.success("Item updated ");

          getCart().then((val) => {
            if (val) {
              handleClose();
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const addToCartHandler = () => {
    let payload;

    if (data.isPickupService) {
      payload = {
        serviceId: data.id,
        pickupDetail: {
          // airline: pickupDetails.aireline,
          flightNumber: pickupDetails.flightNumber,
          // timeOfArrival: pickupDetails.arrivalTime,
          airline: pickupDetails.airline,

          timeOfArrival: pickupDetails.timeOfArrival,
        },
      };
    } else {
      const a = convertISOTimeToLocaleTime(scheduled.date, true);
      const b = scheduled.time;

      // Extracts the time portion from 'a'

      // Combine date from 'a' and time from 'b' to create the final merged string
      const mergedDateTime = `${a.substr(0, 11)}${b}:00.000Z`;

      console.log("mergedDateTime => ", mergedDateTime + "   : ", a); // Output the merged string

      payload = {
        serviceId: data.id,
        bookingSlotDate: mergedDateTime,
        bookingSlotTime: scheduled.time,
        instructions: "string",
      };
    }

    // if (onEdit) {
    //   if (!ServiceData?.isPickupService) {
    //     payload = {
    //       bookingSlotDate: convertISOTimeToLocaleTime(scheduled.date, true),
    //       bookingSlotTime: scheduled.time,
    //       instructions: "string",
    //     };
    //   } else {
    //     payload = {};
    //   }
    // }

    if (!payload) {
      return;
    }

    console.log("addToCartHandler => ", payload);

    // return

    if (onEdit) {
      editService(payload);
    } else {
      if (!display) {
        axios
          .post("/customers/cart-item/addCartItem", payload)
          .then((res) => {
            toast.success("Item Added to Cart!");
            getCart().then((val) => {
              if (val) {
                handleClose();
              }
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  return (
    <div className="px-10 pb-4 max-h-[600px] overflow-scroll">
      {showCalender ? (
        <CalendarInput
          close={() => {
            setShowCalender(false);
            setScheduled(null);
          }}
          details={setScheduled}
          next={() => setShowCalender(false)}
          scheduled={scheduled}
          availableTime={data?.availableBookingSlotTime}
          convertISOTimeToLocaleTime={convertISOTimeToLocaleTime}
          bookingSlotDateTime={bookingSlotDateTime}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          time={time}
          setTime={setTime}
          date={date}
          setDate={setDate}
        />
      ) : showForm ? (
        <FormInput
          data={data}
          close={() => {
            setShowForm(false);
            if (!onEdit) {
              setPickupDetails(null);
            }
          }}
          pickupDetails={pickupDetails}
          next={() => setShowForm(false)}
          setPickupDetails={setPickupDetails}
        />
      ) : (
        <>
          <img src={logo} alt="" width={250} style={{ margin: "0px auto" }} />
          {!data ? (
            <SkeletonLoad />
          ) : (
            <>
              <ActivityDetails data={data} />
              <ActivityButton
                data={data}
                setShowCalender={setShowCalender}
                setShowForm={setShowForm}
                formatDateTime={formatDateTime}
                scheduled={scheduled}
                pickupDetails={
                  display
                    ? cardData?.pickupDetail
                      ? cardData?.pickupDetail
                      : cardData
                    : pickupDetails
                }
                setValid={setValid}
                display={display}
              />
            </>
          )}

          {display ? (
            <button
              className="rounded-full bg-blue-500 text-white w-full h-10 hover:bg-blue-600 transition duration-300 ease-in-out mt-4 "
              onClick={() => handleClose()}
            >
              Back
            </button>
          ) : (
            <button
              className="rounded-full bg-blue-500 text-white w-full h-10 hover:bg-blue-600 transition duration-300 ease-in-out mt-2 disabled:bg-gray-200 disabled:text-black disabled:cursor-not-allowed"
              onClick={
                token === null ? () => redirect("/login") : addToCartHandler
              }
              disabled={!valid}
            >
              Add to cart
            </button>
          )}
        </>
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getCart: () => dispatch(CartAction.getCart()),
});

const mapStateToProps = (state) => ({
  cart: state.cart.cartData,
  cartItems: state.cart.cartData?.cartItems,
  cartTotal: state.cart.cartTotal,
  paymentMethods: state.cart.paymentMethods,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartModal);
