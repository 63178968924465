import { useNavigate } from "react-router-dom";
import edit from "../../../assets/ui_elements/edit2.svg";
const UserDetails = ({ userInfoData }) => {
  const redirect = useNavigate();
  return (
    <div className="flex justify-start items-center">
      <div className="relative">
        <img
          src={userInfoData?.profilePicURL}
          alt=""
          className="object-cover w-16 h-16 rounded-full mr-4"
        />
        <img
          src={edit}
          alt=""
          width={20}
          className="absolute bottom-0 right-3 cursor-pointer"
          onClick={() => redirect("/user_profile/myProfile")}
        />
      </div>
      <div>
        <h1 className="font-bold text-xl">
          {userInfoData?.firstName} {userInfoData?.lastName}
        </h1>
        <p className="mt-1">
          ({userInfoData?.countryCode}) {userInfoData?.phoneNo}
        </p>
      </div>
    </div>
  );
};
export default UserDetails;
