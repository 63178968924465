import { useState, useEffect } from "react";
import axios from "../../constant/axios";
import ServicesCard from "../services/ServicesCard";
import EmptyCart from "../../components/checkout/EmptyCart";
import Footer from "../../components/Footer/Footer";
import { useSearchParams } from "react-router-dom";

const ServicesUnderCategory = () => {
  const [activities, setActivities] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  const isSubCategory = searchParams.get("isSubCategory");

  const getServiceBycategory = (id) => {
    let url = `/services/getServicesByCategory/${id}`;
    if (isSubCategory === "true") {
      url = `/services/getServicesBySubcategory/${id}?status=true`;
    }
    axios.get(url).then((res) => {
      if (res?.statusCode >= 200 && res?.statusCode <= 299) {
        if (res.data.services.length) {
          setActivities(res.data.services);
        } else {
          setIsEmpty(true);
        }
      }
    });
  };
  useEffect(() => {
    let url = window.location.pathname;
    let categoryId = url.split("/")[2];

    if (categoryId) {
      getServiceBycategory(categoryId);
    }

    // eslint-disable-next-line
  }, []);

  return isEmpty ? (
    <>
      <div className="wrapper w-[100vw] h-[80vh] flex items-center justify-center border ">
        <EmptyCart
          title="There are no Activities Available"
          description="Available activities will show here"
        />
      </div>
      <Footer />
    </>
  ) : (
    <>
      <div className="mt-8 mx-40">
        <h1 className="text-2xl font-bold">Activities</h1>
        <ServicesCard cardsData={activities} />
      </div>
      <Footer />
    </>
  );
};
export default ServicesUnderCategory;
