const TOKEN = "Customer_Access_Token";
const sessionToken = "customer_sessionToken";

const setUserInfo = (value) => {
  localStorage.setItem(TOKEN, value.accessToken);
};
const getToken = () => {
    const token = localStorage.getItem(TOKEN);
    if (!token) {
      return false;
    }
    return token;
  };



export { setUserInfo , getToken };
