import React, { useEffect, useState } from "react";
import Modal from "../../common/Modal/modal";
import Card from "./bookingCard/Card";
import axios from "../../constant/axios";
import { getToken } from "../../constant/localStorage/localStorage";
import BookingDetails from "./bookingDetailModal/bookingDetails";
import Seleton from "./Seleton";
import BookignCancelModal from "./CancelBookingModal/BookignCancelModal";
import { toast } from "react-toastify";

const Bookings = () => {
  const token = getToken();
  const [bookingData, setBookingData] = useState();
  const [modalControl, setModalControl] = useState({
    isVisible: false,
    cancelBooking: false,
    data: [],
  });
  console.log("bookingData => ", bookingData);

  const handleViewDetails = (data) => {
    setModalControl({
      isVisible: true,
      data: data,
    });
  };
  const handleBookingModal = (data) => {
    setModalControl({
      cancelBooking: true,
      data: data,
    });
  };
  const handleClose = () => {
    setModalControl({
      isVisible: false,
      cancelBooking: false,

      data: [],
    });
  };
  const getBookingData = () => {
    axios
      .get("/customers/bookings")
      .then((res) => {
        if (res?.statusCode >= 200 && res?.statusCode <= 299) {
          setBookingData(res?.data?.bookings);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteBooking = (data) => {
    let payload = {
      reasonForCancel: "N/A",
    };

    axios
      .post(`/customers/bookings/cancel/${data?.id}`, payload)
      .then((res) => {
        if (res?.statusCode >= 200 && res?.statusCode <= 299) {
          getBookingData();
          handleClose();
          toast.success("Booking Cancelled");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (token) {
      getBookingData();
    }
  }, [token]);

  return (
    <>
      <BookingDetails
        isVisible={modalControl?.isVisible}
        data={modalControl?.data}
        handleClose={handleClose}
      />

      <BookignCancelModal
        isVisible={modalControl?.cancelBooking}
        handleClose={handleClose}
        data={modalControl?.data}
        title={"Warning"}
        deleteBooking={deleteBooking}
      />
      <h1 className="font-bold text-xl py-3">Booking History</h1>
      <div className="flex w-full justify-start">
        <div className="flex justify-evenly items-center flex-wrap gap-8 overflow-auto">
          {bookingData ? (
            bookingData.length > 0 &&
            bookingData.map((obj, index) => {
              return (
                <Card
                  key={index}
                  data={obj}
                  serviceImg={obj?.bookedServices[0]?.serviceImg}
                  total={obj?.totalServicePayable}
                  handleViewDetails={handleViewDetails}
                  getBookingData={getBookingData}
                  handleBookingModal={handleBookingModal}
                />
              );
            })
          ) : (
            <Seleton />
          )}
        </div>
      </div>
    </>
  );
};

export default Bookings;
