import React, { useEffect, useState } from "react";
import close from "../../assets/ui_elements/close.svg";

const Modal = ({
  isVisible,
  onClose,
  title,
  children,
  className,
  styles,
  width,
}) => {
  const [modalVisible, setModalVisible] = useState(isVisible);

  useEffect(() => {
    setModalVisible(isVisible);
  }, [isVisible]);

  const handleClose = (e) => {
    const { id } = e.target;
    if (id === "modal_wrapper") onClose();
  };

  if (!isVisible) return null;
  return (
    <div
      className={`fixed inset-0 z-50 w-full bg-black bg-opacity-25 backdrop-blur-sm flex justify-center items-center transition-opacity duration-100 ${
        modalVisible ? "opacity-100" : "opacity-0 pointer-events-none"
      }`}
      id="modal_wrapper"
      onClick={handleClose}
    >
      <div
        className={`flex flex-col   min-w-[300px] bg-white rounded-lg py-4 transition-transform duration-100 ${
          modalVisible ? "scale-100" : "scale-95"
        }`}
        style = {styles}
      >
        <div className=" p-2 rounded-md flex justify-between px-5 relative mb-2  ">
          <div className="font-semibold  mx-6" style={{ fontSize: "20px" }}>
            {title}
          </div>
          <div
            className="p-2 cursor-pointer hover:bg-blue-200 rounded-full transition-all duration-0"
            onClick={onClose}
          >
            <img src={close} alt="" width={20} />
          </div>
        </div>
        <div
          className={className && className}
          style={
            width !== undefined
              ? { width: width } //accepts number
              : { width: "400px", boxSizing: "border-box" }
          }
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
