import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Outlet,
  useRoutes,
} from "react-router-dom";
import Myprofile from "../../components/userProfile/Myprofile/Myprofile";
import Footer from "../../components/Footer/Footer";

import ProfileNav from "../../components/userProfile/ProfileNav/ProfileNav";
import { connect } from "react-redux";
import Bookings from "../bookingHistory/Bookings";
import AboutCompany from "../../components/userProfile/about/AboutCompany";
import UserAction from "../../redux/userInfo/userInfo-action";
import Chat from "../home/Chat";

const UserProfile = ({ userInfo, updateCustomer }) => {
  const routes = [
    { path: "/myProfile", element: <Myprofile /> },
    { path: "/bookings", element: <Bookings /> },
    { path: "/about", element: <AboutCompany /> },
  ];
  const element = useRoutes(routes);
  return (
    <div>
      <div className="containerBox flex w-full justify-center ">
        <div className="bg-white mt-16 h-fit rounded-xl px-4 py-4 w-10/12 pb-10 mb-10">
          <div>
            <ProfileNav userInfo={userInfo} />
          </div>
          <div className="mt-4">{element}</div>
        </div>
      </div>
      <Footer />
      <Chat />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  // getUserInfo: () => dispatch(UserAction.getUserInfo()),
});

const mapStateToProps = (state) => ({
  userInfo: state.user.userInfo,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
