import React from "react";
import Modal from "../../../common/Modal/modal";
import "./BookignCancelModal.scss";
import { useNavigate } from "react-router-dom";

const BookignCancelModal = ({
  isVisible,
  handleClose,
  title,
  deleteBooking,
  data,
  delet,
}) => {
  const redirect = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("Customer_Access_Token");
    redirect("/");
    window.location.reload();
  };
  return (
    <>
      <Modal
        isVisible={isVisible}
        width={"450px"}
        title={title}
        onClose={handleClose}
      >
        <div className="flex flex-col items-center px-4">
          <div
            style={{ fontWeight: "500", fontSize: "19px", margin: "1rem 0" }}
          >
            {console.log(delet)}
            {delet === undefined
              ? "Are you sure you want to cancel the booking?"
              : `Are you sure you want to delete ${delet} from cart?`}
          </div>
          <div className="flex justify-between items-center gap-3 mt-5 w-full px-5">
            <button
              className="border w-1/2 py-2 rounded-full border-blue-500 text-blue-500 hover:bg-blue-100"
              style={{ fontWeight: "500" }}
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="border w-1/2 py-2 rounded-full bg-blue-700 border-blue-500 text-white hover:bg-blue-800"
              style={{ fontWeight: "500" }}
              onClick={() => deleteBooking(data)}
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default BookignCancelModal;
