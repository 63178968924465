import React from "react";
import close from "../../assets/ui_elements/close.svg";
import logo from "../../assets/Logo.svg";

const RightDrawerModal = ({ isOpen, onClose, children }) => {
  const modalClass = isOpen ? "translate-x-0" : "translate-x-full";

  return (
    <>
      <div
        className={`fixed top-0 left-0 h-full w-full bg-black bg-opacity-50 z-40 ${modalClass}`}
      ></div>
      <div
        className={`fixed top-0 right-0 h-full bg-white shadow-md transition-transform transform ${modalClass} z-50 p-4`}
        style={{
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          width: "440px",
        }}
      >
        <div>
          <img
            src={close}
            alt=""
            className="cursor-pointer w-9 hover:bg-blue-300 p-2 rounded-full"
            onClick={onClose}
          />
        </div>
        <img src={logo} alt="" className="m-auto w-72" />
        <div className="p-4">{children}</div>
      </div>
    </>
  );
};

export default RightDrawerModal;
