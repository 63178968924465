import { useNavigate } from "react-router-dom";
import "./CartModal.css";
const PaymentStatus = ({ status }) => {
  const redirect = useNavigate();

  const handleViewService = () => {
    redirect("/user_profile/bookings");
  };
  return (
    <div
      className={`anime w-full flex justify-center flex-col items-center pb-6 gap-3`}
    >
      <div className="w-full flex  flex-col justify-center items-center  ">
        {status === "failed" ? (
          <>
            <svg version="1.1" viewBox="0 0 130.2 130.2">
              <circle
                className="path circle"
                fill="none"
                stroke="#D06079"
                stroke-width="6"
                stroke-miterlimit="10"
                cx="65.1"
                cy="65.1"
                r="62.1"
              />
              <line
                className="path line"
                fill="none"
                stroke="#D06079"
                stroke-width="6"
                stroke-linecap="round"
                stroke-miterlimit="10"
                x1="34.4"
                y1="37.9"
                x2="95.8"
                y2="92.3"
              />
              <line
                className="path line"
                fill="none"
                stroke="#D06079"
                stroke-width="6"
                stroke-linecap="round"
                stroke-miterlimit="10"
                x1="95.8"
                y1="38"
                x2="34.4"
                y2="92.2"
              />
            </svg>
            <p className="m-0 text-2xl font-semibold">Booking Failed</p>
          </>
        ) : (
          ""
        )}
        {status === "PAID" ? (
          <>
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 130.2 130.2"
            >
              <circle
                className="path circle"
                fill="none"
                stroke="#73AF55"
                stroke-width="6"
                stroke-miterlimit="10"
                cx="65.1"
                cy="65.1"
                r="62.1"
              />
              <polyline
                className="path check"
                fill="none"
                stroke="#73AF55"
                stroke-width="6"
                stroke-linecap="round"
                stroke-miterlimit="10"
                points="100.2,40.2 51.5,88.8 29.8,67.5 "
              />
            </svg>
            <p className="m-0 text-2xl font-semibold">Congratulations!</p>
          </>
        ) : (
          ""
        )}
      </div>

      {status === "failed" ? (
        <>
          <p className="m-0 text-center">
            Your Booking is failed due to some reason!
          </p>
        </>
      ) : (
        ""
      )}

      {status === "PAID" ? (
        <>
          <p className="m-0 text-center">
            You have successfully booked our Service
          </p>
        </>
      ) : (
        ""
      )}

      {status === "failed" ? (
        <button className="w-8/12 rounded-full border bg-[#1560BD] shadow-lg text-white font-semibold mt-5 py-2">
          Back
        </button>
      ) : (
        ""
      )}
      {status === "PAID" ? (
        <button
          className="w-8/12 rounded-full border bg-[#1560BD] shadow-lg text-white font-semibold mt-5 py-2"
          onClick={handleViewService}
        >
          View Service Orders
        </button>
      ) : (
        ""
      )}
    </div>
  );
};

export default PaymentStatus;
