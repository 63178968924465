import classNames from "classnames";
import styles from "../auth/auth.module.css";

import React from "react";
import Icon from "../../common/Icon/icon";
import { getToken } from "../../constant/localStorage/localStorage";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const WelcomeModal = () => {
  const redirect = useNavigate();
  let token = getToken();
  const handleContinue = () => {
    if (token) {
      redirect("/landing");
    } else {
      toast.warning("Please Login first ");
    }
  };
  return (
    <div className="wraper flex justify-center items-center  ">
      <div
        className={classNames({
          [styles.authForm]: true,
          "justify-between": true,
        })}
        style={{ maxHeight: "25rem", maxWidth: "26rem" }}
      >
        <Icon name="BrandLogo" />

        <div className="w-full flex flex-col mt-4 gap-4 items-center">
          <p className="m-0 text-3xl font-semibold">Welcome</p>
          <p className="text-center text-[14px] text-[#666]">
            Greeting, fellow explorer! Excursionist is your gateway to thrilling
            adventures and unforgettable experiences
          </p>
        </div>

        <button
          className="w-full px-16 py-3 bg-[#1560BD] flex justify-center items-center mt-2 text-white rounded-full cursor-pointer"
          onClick={handleContinue}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default WelcomeModal;
