import React, { useState, useRef, useEffect } from "react";
import Modal from "../../../common/Modal/modal";
import { toast } from "react-toastify";

export default function StripeAddCard({
  isVisible,
  callback,
  link,
  toggleLoader,
}) {
  const Iframe = useRef(null);
  const [visibilityState, setVisibilityState] = useState(
    document.visibilityState
  );

  // console.log("Link***", link)
  const handleIframeLoad = (e) => {
    const content = e?.target?.contentWindow?.location;

    // const url = e?.target?.contentWindow?.location;

    // it works ¯\_(ツ)_/¯
    try {
      // const href = url.href;
      // toastr.success("handleIframeLoad")
      // console.log("handleIframeLoad==", content)
      //
      // callback(false)
      // setTimeout(() => {
      //       callback(false);
      //     }, 3000);
      //   dispatch(controlsActions.stopLoading());
    } catch (e) {
      // console.log(e);

      setTimeout(() => {
        callback(false);
      }, 3000);
    }
  };
  const header = () => {
    return <div>Add New Card</div>;
  };

  return (
    <Modal
      header={header()}
      // customLeft={isBackPresent ? null : <Logo className={styles.customLogo} />}

      isVisible={isVisible}
    >
      {isVisible && (
        <iframe
          ref={Iframe}
          className="w-full"
          title="Zaarol | Add Card"
          onLoad={() => handleIframeLoad()}
          src={
            "https://checkout.stripe.com/c/pay/cs_test_c1i6Fr3RagtERMu6kMKUDOJ0qtYZgtu9ngbG0gnUqLTK8VxncMMfucgAya#fidkdWxOYHwnPyd1blpxYHZxWjA0S0NoMHRBUDRhSU88cU58M2AyXG9MXXdPVENGUEpIMFw3Q3Rdc2NQPXVpdWg9ZkNxcjFhPENqf1czd3BtSkR2MWhyNDx0QWZGTnB%2FTWtKcHdrXUZOUDM3NTVHfVNwPTxMaycpJ2N3amhWYHdzYHcnP3F3cGApJ2lkfGpwcVF8dWAnPyd2bGtiaWBaZmppcGhrJyknYGtkZ2lgVWlkZmBtamlhYHd2Jz9xd3BgeCUl"
          }
        ></iframe>
      )}
    </Modal>
  );
}
