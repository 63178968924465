import { toast } from "react-toastify";
import search from "../../assets/ui_elements/search.svg";
import { useState } from "react";
import axiosClient from "../../constant/axios";
import Activity from "../Home/Activity/Activity";
import ActivityCards from "../Home/Activity/ActivityCards";
import Modal from "../../common/Modal/modal";
import AddToCartModal from "../Home/Activity/AddToCartModal";
const SearchBox = () => {
  const [searchService, setSearchService] = useState("");
  const [searchFlag, setSearchFlag] = useState(false);
  const [bookingModal, setBookingModal] = useState(false);
  const [serviceId, setServiceId] = useState();

  const getSearchService = (value) => {
    if (!value) {
   
      return;
    }
    let query = {
      params: {
        status: true,
        search: value,
      },
    };

    axiosClient.get("/services", query).then((res) => {
      if (res?.statusCode >= 200 && res?.statusCode <= 299) {
        if (res?.data?.serviceCount) {
          setSearchService(res?.data?.services);
          setSearchFlag(true);
        }
      }
    });
  };

  let debounceTimer;
  const handleSearch = (e) => {
    if (e.target.value.length >= 3) {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        getSearchService(e.target.value);
      }, [1000]);
    }
    else if (!e.target.value){
      setSearchService("")
      setSearchFlag(false)

    }

  };

  const handleClose = () => {
    setBookingModal(false);
  };
  const openBookingModalHandler = (id) => {
    setSearchFlag(false);
    setServiceId(id);
    setBookingModal(true);
  };

  return (
    <div
      className="flex flex-col md:flex-row bg-gray-100  rounded-md relative "
      style={{
        width: "45%",
      }}
    >
      <div className=" w-full flex flex-col md:flex-row bg-gray-100 px-3 py-1 rounded-md">
        <img src={search} alt="" width={20} className="mr-3" />
        <input
          type="text"
          id="search"
          className="px-2 py-1 pl-3 rounded-md border-none bg-transparent flex-grow md:mr-2 outline-none focus:outline-none"
          style={{
            width: "100%",
          }}
          placeholder="Search by title"
          onChange={handleSearch}
        />
      </div>

      {searchFlag && (
        <div
          className=" w-full min-h-36  py-2 px-2 flex gap-4 flex-wrap  bg-white border absolute top-10 rounded-b-lg z-20"
          onMouseLeave={() => setSearchFlag(false)}
        >
          { searchService && Array.isArray(searchService) &&  searchService.length > 0 &&  searchService?.map((item, index) => {
            return (
              <>
                <div
                  key={index}
                  className="w-40 h-40 relative border bg-black rounded-lg cursor-pointer"
                  style={{
                    background: `url(${item.serviceImg})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                  onClick={() => openBookingModalHandler(item.id)}
                >
                  <div className="absolute inset-0 flex flex-col items-center justify-end">
                    <h1 className="relative text-center p-2 w-full text-white bg-opacity-50 backdrop-blur-md rounded-b-lg">
                      {item?.title}
                    </h1>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      )}

      <Modal
        isVisible={bookingModal}
        onClose={handleClose}
        children={
          <AddToCartModal cartId={serviceId} handleClose={handleClose} />
        }
      />
    </div>
  );
};
export default SearchBox;
