import { useEffect, useState } from "react";

import service from "../../../assets/checkout/testService.jpg";
import CartCard from "./CartCard";

import { connect, useSelector } from "react-redux";
import AddressEditBox from "./AddressEditBox";
import { getToken } from "../../../constant/localStorage/localStorage";
import UserAction from "../../../redux/userInfo/userInfo-action";
import CartAction from "../../../redux/cart/cart-action";

const LeftContainer = ({ cart, cartTotal, removeFromCart }) => {
  console.log();

  const token = getToken();
  const activeAddress = useSelector((state) => state.cart.cartData);

  const [orders, setOrders] = useState([]);
  console.log("handleSetOrders => ", orders);

  const handleSetOrders = (data) => {
    setOrders(data);
  };

  const [isAddressEditable, setIsAddressEditable] = useState(false);

  const [details, setDetails] = useState({});

  const [isDetailsChanged, setIsDetailsChanged] = useState(false);

  useEffect(() => {
    if (token && cart) {
      handleSetOrders(cart?.cartItems);
    }
  }, [token, cart]);

  const increaseQuantity = (id) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.id === id ? { ...order, quantuty: order.quantuty + 1 } : order
      )
    );
  };

  const decreaseQuantity = (id) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.id === id
          ? { ...order, quantuty: Math.max(order.quantuty - 1, 1) }
          : order
      )
    );
  };

  const removeOrder = (id) => {
    setOrders((prevOrders) => prevOrders.filter((order) => order.id !== id));
  };

  return (
    <div>
      {/* Order Items */}
      <h1 className="text-2xl font-bold mb-5" style={{ width: "600px" }}>
        Orders
      </h1>
      <div
        className="p-6 rounded-lg border flex flex-col  gap-4 overflow-auto"
        style={{
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          maxHeight: "70vh",
        }}
      >
        {orders &&
          orders.map((item, index) => {
            const isLastItem = index === orders?.length - 1;
            return (
              <CartCard
                cartData={cart}
                item={item}
                isLastItem={isLastItem}
                key={Math.random()}
                onRemove={() => removeOrder(item.id)}
                onIncrease={() => increaseQuantity(item.id)}
                onDecrease={() => decreaseQuantity(item.id)}
              />
            );
          })}
        {orders?.length === 0 && (
          <>
            <h1>Your cart is empty!</h1>
            <h1>Add services to get started.</h1>
          </>
        )}
      </div>

      {/* Billing Addess */}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  // getUserInfo: () => dispatch(UserAction.getUserInfo()),
  removeFromCart: (id) => dispatch(CartAction.removeFromCart(id)),
});

const mapStateToProps = (state) => ({
  cart: state.cart.cartData,
  cartTotal: state.cart.cartTotal,
});

export default connect(mapStateToProps, mapDispatchToProps)(LeftContainer);
