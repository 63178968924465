import moment from "moment";

const ErrorMessages = {
  en: {
    empty: (valueName) => `Please fill the ${valueName || "field"}`,
    invalid: (valueName) => `${valueName || "Field"} is invalid`,
    price: (valueName) => `${valueName} can't be greater than 10 lakh`,
    numeric: (valueName) =>
      `${valueName || "Field"} should only contain numbers`,
    alphaNumeric: (valueName) =>
      `${valueName || "Field"} should only contain Alphanumeric value`,
    minCharLimit: (valueName, limit) =>
      `${valueName || "Field"} must contain atleast ${limit} characters.`,
    startEndDateEqual: () => `Start Date can't be equal to End Date`,
    endStartDateEqual: () => `End Date can't be equal to Start Date`,
    startEndDateDiff: () => `Start Date cannot be ahead of End Date`,
    endStartDateDiff: () => `End Date cannot be behind Start Date`,
    srcDestEqual: () => `Source & Destination day can't be same`,
    noMatch: (valueName) => `Password do not match`,
  },

  ar: {
    empty: (valueName) => `يرجى ملء ${valueName || "الحقل"}`,
    invalid: (valueName) => `${valueName || "الحقل"} غير صالح`,
    numeric: (valueName) =>
      valueName === undefined
        ? "يجب أن يحتوي الحقل على أرقام فقط"
        : `يجب أن يحتوي ${valueName} على أرقام فقط
      `,
    minCharLimit: (valueName, limit) =>
      valueName === undefined
        ? `يجب أن يحتوي الحقل على ${limit} حرفًا على الأقل`
        : `يجب أن تحتوي ${valueName} على أحرف ${limit} على الأقل`,
    startEndDateEqual: () =>
      `لا يمكن أن يكون تاريخ البدء مساويًا لتاريخ الانتهاء`,
    endStartDateEqual: () =>
      `لا يمكن أن يكون تاريخ الانتهاء مساويًا لتاريخ البدء`,
    startEndDateDiff: () => `لا يمكن أن يكون تاريخ البدء قبل تاريخ الانتهاء`,
    endStartDateDiff: () => `لا يمكن أن يكون تاريخ الانتهاء بعد تاريخ البدء`,
    srcDestEqual: () => `لا يمكن أن يكون يوم المصدر والوجهة متطابقين`,
    noMatch: (valueName) => `Password do not match`,
  },

  fr: {
    empty: (valueName) => `veuillez remplir le ${valueName || "champ"}`,
    invalid: (valueName) => `${valueName || "le champ"} n'est pas valide`,
    numeric: (valueName) =>
      `${valueName || "le champ "} ne doit contenir que des chiffres`,
    minCharLimit: (valueName, limit) =>
      `${valueName || "le champ"} doit contenir au moins ${limit} caractères`,
    startEndDateEqual: () =>
      `La date de début ne peut pas être égale à la date de fin`,
    endStartDateEqual: () =>
      `La date de fin ne peut pas être égale à la date de début`,
    startEndDateDiff: () =>
      `La date de début ne peut pas être antérieure à la date de fin`,
    endStartDateDiff: () =>
      `La date de fin ne peut pas être postérieure à la date de début`,
    srcDestEqual: () =>
      `La date de fin ne peut pas être postérieure à la date de début`,
    noMatch: (valueName) => `Password do not match`,
  },

  de: {
    empty: (valueName) => `Bitte füllen Sie das ${valueName || "Feld"} aus`,
    invalid: (valueName) => `${valueName || "Feld"} ist ungültig`,
    numeric: (valueName) =>
      `Das ${valueName || "Feld"} sollte nur Zahlen enthalten`,
    minCharLimit: (valueName, limit) =>
      `Das ${valueName || "Feld"} muss mindestens ${limit} Zahlen enthalten`,
    startEndDateEqual: () =>
      `Das Startdatum darf nicht gleich dem Enddatum sein`,
    endStartDateEqual: () =>
      `Das Enddatum darf nicht gleich dem Startdatum sein`,
    startEndDateDiff: () => `Das Startdatum darf nicht vor dem Enddatum liegen`,
    endStartDateDiff: () =>
      `Das Enddatum darf nicht hinter dem Startdatum liegen`,
    srcDestEqual: () => `Quell- und Zielwerte können nicht identisch sein`,
    noMatch: (valueName) => `Password do not match`,
  },
  tr: {
    empty: (valueName) => `Lütfen ${valueName || "Alanını"} doldurun`,
    invalid: (valueName) => `${valueName || "Alan"} geçersiz`,
    numeric: (valueName) => `${valueName || "Alan"} yalnızca sayı içermelidir`,
    minCharLimit: (valueName, limit) =>
      `${valueName || "Alan"} en az ${limit} karakter içermelidir.`,
    startEndDateEqual: () => `Başlangıç ​​Tarihi, Bitiş Tarihine eşit olamaz`,
    endStartDateEqual: () => `Bitiş Tarihi, Başlangıç ​​Tarihine eşit olamaz`,
    startEndDateDiff: () => `Başlangıç ​​Tarihi, Bitiş Tarihinden önce olamaz`,
    endStartDateDiff: () => `Bitiş Tarihi, Başlangıç ​​Tarihinden sonra olamaz`,
    srcDestEqual: () => `Kaynak ve Hedef günü aynı olamaz`,
    noMatch: (valueName) => `Parola eşleşmedi`,
  },
};

const getErrorMessage = (type, field, field2) => {
  const language = "en";
  return ErrorMessages[language][type](field, field2);
};

const RegularExpressions = {
  // phoneNumber: /^\d{4,14}$/,
  phoneNumber: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
  name: /^[a-zA-Z\s]*$/,
  // margin:/[+\-0-9]+/g,
  margin: /((\d+)((\.\d{1,2})?))$/,
  textField: /[^a-z0-9]/gi,
  numeric: /^[0-9]+(\.[0-9]+)?$/,
  alphaNumeric: /^[a-zA-Z0-9]+$/,
  rank: /^[0-9]+$/,
  barCode: /^(?![0-9]*$)[a-zA-Z0-9]+$/,
  // email: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,

  // email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,

  email:
    /^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/,
  // address: /^[#.0-9a-zA-Z\s,-]+$/,
};

const FieldNames = {
  NAME: "Name",
  PHONE_NUMBER: "Phone Number",
  EMAIL: "E-Mail",
  PASSWORD: "Password",
  ADDRESS: "Address",
  CONFIRM: "Confirm Password",
};

// const initialState = {
//     value: '',
//     errMsg: [],
// }
class Validations {
  static validateName = (name, specific = false, field) => {
    const state = {
      value: name,
      isValid: false,
      errMsg: [],
    };

    if (specific && !RegularExpressions.name.test(name)) {
      state.errMsg.push(getErrorMessage("invalid", field));
      return state;
    }

    if (!name || name === "") {
      state.errMsg.push(getErrorMessage("empty", FieldNames.NAME));
    }
    // name.trim();
    state.isValid = true;
    return state;
  };
  static validateBarCode = (value, field) => {
    const state = {
      value,
      isValid: false,
      errMsg: [],
    };

    if (!RegularExpressions.barCode.test(value)) {
      state.errMsg.push(getErrorMessage("invalid", field));
      return state;
    }

    if (!value || value === "") {
      state.errMsg.push(getErrorMessage("empty", field));
    }
    // name.trim();
    state.isValid = true;
    return state;
  };

  static validatePhoneNumber = (number) => {
    const state = {
      value: number,
      isValid: false,
      errMsg: [],
    };

    if (!number) {
      state.errMsg.push(getErrorMessage("empty", FieldNames.PHONE_NUMBER));
      return state;
    }

    if (!RegularExpressions.phoneNumber.test(number)) {
      state.errMsg.push(getErrorMessage("invalid", FieldNames.PHONE_NUMBER));
      return state;
    }
    // number.trim();
    state.isValid = true;

    return state;
  };

  static validateMarginValue = (value, noZero = false, field) => {
    const state = {
      value,

      isValid: false,
      errMsg: [],
    };

    if (!value && value !== 0) {
      state.errMsg.push(getErrorMessage("empty", field));
      return state;
    }

    if (noZero === true && value === 0) {
      state.errMsg.push(getErrorMessage("invalid", field));
      return state;
    }

    if (!RegularExpressions.margin.test(value)) {
      state.errMsg.push(getErrorMessage("numeric", field));
      return state;
    }

    state.isValid = true;
    return state;
  };

  static validateNumericValue = (
    value,
    noZero = false,
    integer = false,
    price = false,
    field
  ) => {
    const state = {
      value,

      isValid: false,
      errMsg: [],
    };

    if (!value && value !== 0) {
      state.errMsg.push(getErrorMessage("empty", field));
      return state;
    }

    if (noZero === true && value === 0) {
      state.errMsg.push(getErrorMessage("invalid", field));
      return state;
    }
    if (integer === true && !RegularExpressions.rank.test(value)) {
      state.errMsg.push(getErrorMessage("invalid", field));
      return state;
    }
    if (price === true && value >= 1000000) {
      state.errMsg.push(getErrorMessage("price", field));
      return state;
    }

    if (!RegularExpressions.numeric.test(value)) {
      state.errMsg.push(getErrorMessage("numeric", field));
      return state;
    } else if (value.toString().length >= 14) {
      // state.errMsg.push(ErrorMessages.intLimit(field, 14));
      // return state;
    }

    state.isValid = true;
    return state;
  };
  static validateAlphaNumericValue = (
    value,

    field
  ) => {
    const state = {
      value,
      isValid: false,
      errMsg: [],
    };

    if (!value && value !== 0) {
      state.errMsg.push(getErrorMessage("empty", field));
      return state;
    }

    console.log("Regular==>", RegularExpressions.alphaNumeric.test(value));
    if (!RegularExpressions.alphaNumeric.test(value)) {
      state.errMsg.push(getErrorMessage("alphaumeric", field));
      return state;
    }

    state.isValid = true;
    return state;
  };

  static validatePassword = (value, mode = { optional: false }) => {
    const state = {
      value,
      isValid: false,
      errMsg: [],
    };
    if (value === "" || value === null || value === undefined) {
      if (mode.optional) {
        return {
          value,
          isValid: true,
          errMsg: [],
        };
      }
    }

    if (!value) {
      state.errMsg.push(getErrorMessage("empty", FieldNames.PASSWORD));
      return state;
    }

    if (value.length < 8) {
      state.errMsg.push(
        getErrorMessage("minCharLimit", FieldNames.PASSWORD, 8)
      );
      return state;
    }
    state.isValid = true;
    return state;
  };

  static validateAddress = (value) => {
    const state = {
      value,
      isValid: false,
      errMsg: [],
    };

    if (!value) {
      state.errMsg.push(getErrorMessage("empty", FieldNames.ADDRESS));
      return state;
    }

    if (value.length < 5) {
      state.errMsg.push(getErrorMessage("minCharLimit", FieldNames.ADDRESS, 5));
      return state;
    }
    // if (!RegularExpressions.address.test(value)) {
    //   state.errMsg.push(getErrorMessage("invalid", FieldNames.ADDRESS));
    //   return state;
    // }

    state.value = value.trim();
    state.isValid = true;
    return state;
  };

  static validateConfirmPassword = (value, confirm) => {
    const state = {
      value,
      isValid: false,
      errMsg: [],
    };
    if (!value.trim()) {
      state.errMsg.push(getErrorMessage("empty", FieldNames.PASSWORD));
      return state;
    }

    if (value.length < 8) {
      state.errMsg.push(
        getErrorMessage("minCharLimit", FieldNames.PASSWORD, 8)
      );
      return state;
    }
    if (value !== confirm) {
      state.errMsg.push(getErrorMessage("empty", FieldNames.CONFIRM));
      return state;
    }
    state.isValid = true;
    return state;
  };

  static validateTextField = (value, field) => {
    const state = {
      value,
      isValid: false,
      errMsg: [],
    };

    if (!value) {
      state.errMsg.push(getErrorMessage("empty", field));
      return state;
    }

    state.isValid = true;
    return state;
  };

  static validateEmail = (value) => {
    const state = {
      value,
      isValid: false,
      errMsg: [],
    };

    if (!value) {
      state.errMsg.push(getErrorMessage("empty", FieldNames.EMAIL));
      return state;
    }

    if (!RegularExpressions.email.test(value)) {
      state.errMsg.push(getErrorMessage("invalid", FieldNames.EMAIL));
      return state;
    }

    state.isValid = true;
    return state;
  };

  static validateStartEndDate = (start, end) => {
    const startState = {
      value: start,
      isValid: true,
      errMsg: [],
    };

    const endState = {
      value: end,
      isValid: true,
      errMsg: [],
    };

    if (!start) {
      startState.errMsg.push(getErrorMessage("empty"));
      startState.isValid = false;
    }

    if (!end) {
      endState.errMsg.push(getErrorMessage("empty"));
      endState.isValid = false;
    }

    if (start && end) {
      console.log("jackpot");
      if (moment(end).diff(moment(start), "hours") === 0) {
        startState.errMsg.push(getErrorMessage("startEndDateEqual"));
        startState.isValid = false;
        endState.errMsg.push(getErrorMessage("endStartDateEqual"));
        endState.isValid = false;
      } else if (moment(end).diff(moment(start), "hours") < 0) {
        startState.errMsg.push(getErrorMessage("startEndDateDiff"));
        startState.isValid = false;
        endState.errMsg.push(getErrorMessage("endStartDateDiff"));
        endState.isValid = false;
      }
    }
    return {
      startDate: startState,
      endDate: endState,
    };
  };
  static validateStartEndDate2 = (start, end) => {
    const startState = {
      value: start,
      isValid: true,
      errMsg: [],
    };

    const endState = {
      value: end,
      isValid: true,
      errMsg: [],
    };

    if (!start) {
      startState.errMsg.push(getErrorMessage("empty"));
      startState.isValid = false;
    }

    if (!end) {
      endState.errMsg.push(getErrorMessage("empty"));
      endState.isValid = false;
    }

    if (start && end) {
      console.log("jackpot");
      if (moment(end).diff(moment(start), "hours") === 0) {
        // startState.errMsg.push(getErrorMessage("startEndDateEqual"));
        // startState.isValid = false;
        // endState.errMsg.push(getErrorMessage("endStartDateEqual"));
        // endState.isValid = false;
      } else if (moment(end).diff(moment(start), "hours") < 0) {
        startState.errMsg.push(getErrorMessage("startEndDateDiff"));
        startState.isValid = false;
        endState.errMsg.push(getErrorMessage("endStartDateDiff"));
        endState.isValid = false;
      }
    }
    return {
      startDate: startState,
      endDate: endState,
    };
  };

  static validateSourceDestDay = (source, dest) => {
    const sourceState = {
      value: source,
      isValid: true,
      errMsg: [],
    };

    const destState = {
      value: dest,
      isValid: true,
      errMsg: [],
    };

    if (!source && source !== 0) {
      sourceState.errMsg.push(getErrorMessage("empty"));
      sourceState.isValid = false;
    }

    if (!dest && dest !== 0) {
      destState.errMsg.push(getErrorMessage("empty"));
      destState.isValid = false;
    }

    if (source && dest) {
      console.log("jackpot");
      if (source === dest) {
        sourceState.isValid = false;
        destState.isValid = false;
        sourceState.errMsg.push(getErrorMessage("srcDestEqual"));
        destState.errMsg.push(getErrorMessage("srcDestEqual"));
      }
    }
    console.log("source", sourceState);
    return {
      sourceDay: sourceState,
      destDay: destState,
    };
  };

  static validateMultiSelect = (value, field) => {
    const state = {
      value,
      isValid: false,
      errMsg: [],
    };
    if (!value) {
      state.errMsg.push(getErrorMessage("empty", field));
      return state;
    }

    if (!value.length) {
      state.errMsg.push(getErrorMessage("empty", field));
      return state;
    }

    state.isValid = true;
    return state;
  };
}

// export { Validations as default, getErrorMessage };
export default Validations;
