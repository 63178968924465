import { useState, useEffect } from "react";

import Validations from "../../../helper/validations/validations";

import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import dayjs from "dayjs";
import moment from "moment";

function formatDateToISOString(inputDate) {
  const dateParts = inputDate.split("-");
  const year = parseInt(dateParts[0], 10);
  const month = parseInt(dateParts[1], 10);
  const day = parseInt(dateParts[2], 10);

  const dateObject = new Date(year, month - 1, day + 1);

  const isoString = dateObject.toISOString();

  return isoString;
}

const FormInput = ({ data, close, setPickupDetails, next, pickupDetails }) => {
  console.log("pickupDetails = > ", pickupDetails)
  const currentDate = new Date().toISOString().split("T")[0];

  const [airline, setAireline] = useState();
  const [number, setNumber] = useState();
  const [date, setDate] = useState();
  const [parsedDate, setParsedDate] = useState("2023-09-12T21:45:00.000Z");
  console.log("parsedDate => ", parsedDate);

  const [touched, setTouched] = useState({
    airline: false,
    number: false,
    date: false,
  });

  const [validity, setValidity] = useState({
    airline: false,
    number: false,
    date: false,
  });

  const handelAirelineChange = (e) => {
    setPickupDetails({
      ...pickupDetails,
      airline: e.target.value
    })
    setTouched({
      ...touched,
      airline: true,
    });
    setValidity({
      ...validity,
      airline: Validations.validateTextField(e.target.value).isValid,
    });
    setAireline(e.target.value);
  };

  const handelNumberChange = (e) => {

    setPickupDetails({
      ...pickupDetails,
      flightNumber: e.target.value
    })




    setTouched({
      ...touched,
      number: true,
    });
    setValidity({
      ...validity,
      number: Validations.validateTextField(e.target.value).isValid,
    });
    setNumber(e.target.value);
  };

  const handelDateChange = (e) => {
    setTouched({
      ...touched,
      date: true,
    });
    setValidity({
      ...validity,
      date: true,
    });


    setDate(formatDateToISOString(e.target.value));
  };

  const handelDateTimeChange = (val) => {
    const dateObj = new Date(val);
    const formatADate = dateObj.toISOString();


    setTouched({
      ...touched,
      date: true,
    });
    setValidity({
      ...validity,
      date: true,
    });

    setPickupDetails({
      ...pickupDetails,
      timeOfArrival: formatADate
    })



    setDate(formatADate);
  };

  useEffect(() => {
    if (pickupDetails?.airline && pickupDetails?.flightNumber && pickupDetails?.timeOfArrival) {
      setPickupDetails({
        airline: pickupDetails?.airline,
        flightNumber: pickupDetails?.flightNumber,
        timeOfArrival: pickupDetails?.timeOfArrival,
      });
    }
  }, [pickupDetails]);

  const handelContinueButton = () => {
    if (pickupDetails?.airline && pickupDetails?.flightNumber && pickupDetails?.timeOfArrival) {
      next();
    } else {
      setTouched({
        airline: true,
        number: true,
        date: true,
      });
    }
  };

  // useEffect(() => {
  //   // Check if pickupDetails?.timeOfArrival is a valid ISOString
  //   console.log(
  //     "pickupDetails?.timeOfArrival before parsing:",
  //     pickupDetails?.timeOfArrival
  //   );
  //   try {
  //     if (
  //       pickupDetails?.timeOfArrival &&
  //       moment(pickupDetails?.timeOfArrival).isValid()
  //     ) {
  //       const parsed = moment(pickupDetails.timeOfArrival);
  //       setParsedDate(parsed);
  //     } else {
  //       setParsedDate(null);
  //     }
  //   } catch (error) {
  //     // Handle errors gracefully
  //     console.error("pickupDetails?.timeOfArrival Error:  ", error);
  //   }
  // }, [pickupDetails?.timeOfArrival]);

  return (
    <>
      <p
        className="text-sm text-blue-500 cursor-pointer text-right"
        style={{ fontWeight: "500" }}
        onClick={() => close()}
      >
        Back
      </p>
      <div className="flex justify-between items-center mt-4">
        <h1 className="font-bold" style={{ fontSize: "17px" }}>
          {data?.title}
        </h1>
        <p className="text-blue-500 font-bold">${data?.actualPrice}</p>
      </div>
      <div className="flex flex-col justify-start gap-2 mt-2">
        <label htmlFor="Aireline">Aireline</label>
        <input
          type="text"
          id="Aireline"
          value={pickupDetails?.airline}
          onChange={handelAirelineChange}
          className="bg-gray-200 border-none rounded-md p-2"
          placeholder="Enter Airline"
        />
        {touched.airline && !pickupDetails?.airline && (
          <p className="text-red-500">Enter Aireline</p>
        )}
      </div>
      <div className="flex flex-col justify-start gap-2 mt-2">
        <label htmlFor="Number">Flight Number</label>
        <input
          type="number"
          id="Number"
          value={pickupDetails?.flightNumber}
          onChange={handelNumberChange}
          className="bg-gray-200 border-none rounded-md p-2"
          placeholder="Enter Flight Number"
        />
        {touched.number && !pickupDetails?.flightNumber && (
          <p className="text-red-500">Enter Flight Number</p>
        )}
      </div>
      <div className="flex flex-col justify-start gap-2 mt-2">
        <label htmlFor="date">Select Your Arrival Time</label>

        {/* <input
          type="date"
          id="date"
          onChange={handelDateChange}
          className="bg-gray-200 border-none rounded-md p-2"
          placeholder="Select Date"
          min={currentDate}
        /> */}

        <MobileDateTimePicker
          disablePast
          value={pickupDetails?.timeOfArrival ? dayjs(pickupDetails?.timeOfArrival) : ""}
          onChange={handelDateTimeChange}
        />

        {touched.date && !pickupDetails?.timeOfArrival && (
          <p className="text-red-500">Select Date First</p>
        )}
      </div>
      <button
        className="rounded-full bg-blue-500 text-white w-full h-10 hover:bg-blue-600 transition duration-300 ease-in-out mt-5"
        onClick={handelContinueButton}
      >
        Continue
      </button>
    </>
  );
};
export default FormInput;
