import React, { useEffect, useState } from "react";
import { getToken } from "../../../constant/localStorage/localStorage";
import RateUsModal from "../bookingDetailModal/RateUsModal";
import useDeleteButton from "../../../helper/DeleteCountDown/useDeleteButton";
import axios from "../../../constant/axios";
import { toast } from "react-toastify";
import Modal from "../../../common/Modal/modal";
import AddToCartModal from "../../../components/Home/Activity/AddToCartModal";

function formatDate(inputDateTime) {
  const [datePart, timePart] = inputDateTime.split("T");
  const dateObj = new Date(datePart);
  const options = { month: "long", day: "numeric" };
  const formattedDate = dateObj.toLocaleDateString("en-US", options);
  const weekdayAbbreviation = dateObj.toLocaleDateString("en-US", {
    weekday: "short",
  });

  const utcDate = new Date(`${datePart}T${timePart}`);
  const options2 = { hour: "numeric", minute: "2-digit", hour12: true };
  const localTime = utcDate.toLocaleTimeString(undefined, options2);

  const formattedDateTime = `${formattedDate} (${weekdayAbbreviation}), ${localTime}`;

  return formattedDateTime;
}

const Card = ({
  data,
  bookingDetail,
  serviceImg,
  total,
  handleViewDetails,
  getBookingData,
  description,
  handleClose,
  handleBookingModal,
  compact = false,
}) => {
  const token = getToken();

  const [createAt, setCreateAt] = useState();

  const changeDateTimetoLocalTimeZone = () => {
    const gmtDate = new Date(data?.createdAt);
    const options = {
      hour12: true, // Use 24-hour format
      date: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      day: "numeric",
      month: "short", // Use "short" for abbreviated month (e.g., "May" instead of "May")
      year: "numeric",
      // second: "2-digit",
      // timeZoneName: "short",
    };
    // Get the local time string in the user's time zone
    const localTimeString = gmtDate.toLocaleString(undefined, options);

    setCreateAt(localTimeString);
  };

  const [CardData, setCardData] = useState();
  const handleSetCardData = (data) => {
    setCardData(data);
  };

  const [displayViewModal, setDisplayViewModal] = useState(false);
  const [displayRateUsModal, setDisplayRateUsModal] = useState(false);

  useEffect(() => {
    if (token && data) {
      handleSetCardData(data);
      changeDateTimetoLocalTimeZone();
    }
  }, [data, token]);

  const deleteBooking = () => {
    let payload = {
      reasonForCancel: "N/A",
    };

    axios
      .post(`/customers/bookings/cancel/${data?.id}`, payload)
      .then((res) => {
        if (res?.statusCode >= 200 && res?.statusCode <= 299) {
          toast.success("Booking Cancelled");
          getBookingData();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const DeleteButton = useDeleteButton(
    "Cancel Booking",

    deleteBooking,
    "px-4 py-2 text-center  w-full rounded-full border text-[#1560BD] border-[#1560BD] cursor-pointer"
  );

  return (
    <>
      <div className="w-96 px-4 py-4 rounded-xl bg-[#ecf5ff] flex flex-col gap-4  ">
        <div className="flex gap-4">
          <div className="rounded-xl overflow-hidden">
            {serviceImg && (
              <img
                className={`object-cover ${
                  compact ? "w-20 h-20" : "w-[6.5rem] h-[6.5rem] "
                }`}
                src={serviceImg}
                alt="bookingImg"
              />
            )}
          </div>
          <div>
            <div className="font-semibold text-[1.1rem]">
              {bookingDetail ? CardData?.title : CardData?.booking_id}
            </div>
            {compact && (
              <div
                className={
                  compact
                    ? "font-semibold"
                    : "font-semibold text-[1.25rem] text-[#1560BD]"
                }
              >
                $ {total}
              </div>
            )}
            <div className={compact && "font-semibold text-[#1560BD]"}>
              {bookingDetail
                ? data.isPickupService
                  ? formatDate(data.pickupDetail.timeOfArrival)
                  : formatDate(data.bookingSlotDate)
                : createAt}
            </div>

            {!compact && (
              <div className="font-semibold text-[1.25rem] text-[#1560BD]">
                $ {total}
              </div>
            )}
          </div>
        </div>

        {bookingDetail ? (
          <>
            <div>{description}</div>
          </>
        ) : (
          ""
        )}
        <div className="flex justify-between w-full gap-4">
          {bookingDetail ? (
            <div
              className="px-4 py-2 text-center  w-full rounded-full border text-[#1560BD] border-[#1560BD] cursor-pointer"
              onClick={() => setDisplayRateUsModal(true)}
            >
              Rate Us
            </div>
          ) : (
            ""
          )}

          {/* data?.bookingStatus === "CONFIRMED" ? DeleteButton : ""*/}
          {data?.bookingStatus === "CONFIRMED" && (
            <div
              className="px-4 py-2 text-center  w-full rounded-full border text-[#1560BD] border-[#1560BD] cursor-pointer"
              onClick={() => handleBookingModal(data)}
            >
              Cancel Booking
            </div>
          )}

          {bookingDetail ? (
            <div
              className="px-4 py-2 w-full text-center rounded-full border text-white bg-[#1560BD] cursor-pointer"
              onClick={() => setDisplayViewModal(true)}
            >
              View
            </div>
          ) : (
            <div
              className="px-4 py-2 w-full text-center rounded-full border text-white bg-[#1560BD] cursor-pointer"
              onClick={() => handleViewDetails(data)}
            >
              View Booking
            </div>
          )}
        </div>
      </div>

      <RateUsModal
        bookingId={data?.id}
        displayRateUsModal={displayRateUsModal}
        handleClose={() => setDisplayRateUsModal(false)}
      />

      <Modal
        isVisible={displayViewModal}
        onClose={() => {
          handleClose();
          setDisplayViewModal(false);
        }}
        children={
          <AddToCartModal
            cardData={data}
            display={true}
            handleClose={() => setDisplayViewModal(false)}
          />
        }
      />
    </>
  );
};

export default Card;
