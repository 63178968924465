import * as React from "react";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
const Seleton = () => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          gap: 6,
        }}
      >
        <Skeleton variant="rectangular" width={380} height={218} />
        <Skeleton variant="rectangular" width={380} height={218} />
        <Skeleton variant="rectangular" width={380} height={218} />
        <Skeleton variant="rectangular" width={380} height={218} />
        <Skeleton variant="rectangular" width={380} height={218} />
        <Skeleton variant="rectangular" width={380} height={218} />
      </Box>
    </>
  );
};
export default Seleton;
