import React, { useEffect, useState } from "react";
import sideBarData from "../../../constant/sidebar_data/userProfile_sideBar.json";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { getToken } from "../../../constant/localStorage/localStorage";
import UserDetails from "./UserDetails";
import NavButtons from "./NavButtons";
import LogoutModal from "../../logoutModal/LogoutModal";
const ProfileNav = ({ userInfo }) => {
  const token = getToken();

  const [displayLogOutModal, setDisplayLogOutModal] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [userInfoData, setUserInfo] = useState();
  const handleUserInfo = (userInfo) => {
    setUserInfo(userInfo);
  };

  useEffect(() => {
    if (token && userInfo) {
      handleUserInfo(userInfo);
    }
  }, [userInfo, token]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div
        style={{ borderBottom: "1px solid gray" }}
        className={`${windowWidth < 1350 && "pb-4"}`}
      >
        <div className="w-full flex justify-between items-center pb-5">
          <UserDetails userInfoData={userInfoData} />
          {windowWidth > 1350 && <NavButtons />}
          <button
            className="text-red-500 px-20 py-2 rounded-full m-4 font-semibold bg-transparent border border-red-500 hover:bg-red-500 hover:text-white hover:border-transparent  transition duration-300 ease-in-out"
            onClick={() => setDisplayLogOutModal(true)}
          >
            Logout
          </button>
        </div>
        <div className="flex justify-center items-center">
          {windowWidth < 1350 && <NavButtons />}
        </div>
      </div>
      <LogoutModal
        isVisible={displayLogOutModal}
        handleClose={() => setDisplayLogOutModal(false)}
        title={"Logout"}
      />
    </>
  );
};

export default ProfileNav;

{
  /*
<NavLink
            key={`item${index}subItem${item}`}
            // to={"/user_profile/#"}
            to={item.url}
            className={styles.subMenuItem}
            activeClassName={styles.subMenuItemActive}
          >
            <div className={styles.mainCont}>
              <div className={styles.leftCont}>
                <div className={styles.subIcon}></div>
                <div className={styles.text}>{item.name}</div>
              </div>
              <div className={styles.rightCont}></div>
            </div>
          </NavLink>

*/
}
