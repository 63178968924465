import axios from "axios";
import UserAction from "../redux/userInfo/userInfo-action";
import store from "../redux/store";
import { setUserInfo } from "./localStorage/localStorage";
import { toast } from "react-toastify";

let baseURL = "https://api.theexcursionist.net";

const axiosClient = axios.create({
  baseURL,
});

axiosClient.interceptors.request.use(function (config) {
  let jwt = localStorage.getItem("Customer_Access_Token");
  let sessionToken = localStorage.getItem("customer_sessionToken");

  const language = "en";

  if (jwt) {
    config.headers["access-token"] = jwt;
  } else if (sessionToken) {
    config.headers["access-token"] = sessionToken;
  }
  config.baseURL = baseURL;
  config.headers["content-language"] = language ? language : "en";
  return config;
});
axiosClient.interceptors.response.use(
  function (response) {
    if (response.data.statusCode === 200) {
      return response.data;
    } else {
      return response;
    }
  },
  async function (error) {
    console.log("Interceptor: Error response", error);

    if (
      error?.response?.data?.statusCode === 401 &&
      error?.response?.data?.messageCode === "access_token_expired"
    ) {
      try {
        const refreshToken = localStorage.getItem("Customer_Refresh_Token");
        if (refreshToken) {
          const renewedResponse = await axios.post(
            "https://api.theexcursionist.net/admins/renewAccessToken",
            null,
            {
              headers: {
                "refresh-token": refreshToken,
              },
            }
          );

          setUserInfo(renewedResponse?.data?.data);
          window.location.reload();
        }
      } catch (refreshError) {
        store.dispatch(UserAction.sessionExpired());
      }
    } else {
      if (error?.response?.data?.message) {
        toast.error(error.response.data.message);
      } else {
        toast.error("Something Went Wrong");
      }
    }

    return Promise.reject(error.response);
  }
);

export { axiosClient as default, baseURL };
