const TotalAmount = ({ charges, handelCheckout, cartTotal }) => {
  return (
    <>
      <div className="border-b-2 border-blue-500 pb-5 mb-5">
        <div className="flex flex-col ">
          <div className="flex justify-between" >
            <p className="text-lg my-1">Price</p>
            <span className="text-lg my-1">$ {  parseFloat( cartTotal?.totalActualPrice).toFixed(2)}</span>
          </div>
          <div className="flex justify-between" >
            <p className="text-lg my-1">Discount</p>
            <span className="text-lg my-1">-$ {  parseFloat(cartTotal?.discount).toFixed(2) }</span>
          </div>
          <div className="flex justify-between" >
            <p className="text-lg my-1">Promo Discount</p>
            <span className="text-lg my-1">-$ {   parseFloat(cartTotal?.couponDiscount).toFixed(2) }</span>
          </div>
          <div className="flex justify-between" >
            <p className="text-lg my-1">Overall Discount</p>
            <span className="text-lg my-1">-$ {  parseFloat(cartTotal?.overallDiscount).toFixed(2) }</span>
          </div>
        </div>
      </div>
      <div className="flex justify-between">
        <p className="text-lg font-bold">Grand Total</p>
        <span className="text-lg font-bold">$ {parseFloat(cartTotal?.totalServicePayable).toFixed(2)}</span>
      </div>
      <button
        className="bg-blue-600 rounded-full shadow-lg text-white font-semibold mt-5 py-2 w-full hover:bg-blue-700"
        onClick={handelCheckout}
      >
        Checkout
      </button>
    </>
  );
};
export default TotalAmount;
