import { useNavigate } from "react-router-dom";
import side from "../../assets/ui_elements/right.svg";
import edit from "../../assets/ui_elements/edit2.svg";
import LogoutModal from "../logoutModal/LogoutModal";
import { useState } from "react";

const ProfileModal = ({
  isOptionsVisible,
  userInfo,
  options,
  setIsOptionsVisible,
  onMouseLeave,
}) => {
  const redirect = useNavigate();
  const [displayLogOutModal, setDisplayLogOutModal] = useState(false);

  return (
    <>
      {isOptionsVisible ? (
        <>
          <div
            className="absolute p-2 top-12 right-0 mt-2 rounded-lg bg-white shadow-lg flex flex-col justify-center items-center z-20"
            style={{
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
            }}
            onMouseLeave={() => onMouseLeave()}
          >
            <div className="flex justify-evenly items-center">
              <div className="relative">
                <img
                  src={userInfo?.profilePicURL}
                  alt=""
                  className="rounded-full object-cover my-2 mx-4 w-14 h-14 border-blue-300"
                  style={{ borderWidth: "3px" }}
                />
                <img
                  src={edit}
                  alt=""
                  width={20}
                  className="absolute right-3 bottom-2 cursor-pointer"
                  onClick={() => redirect("/user_profile/myProfile")}
                />
              </div>

              <div className="flex flex-col items-center mr-3">
                <h1
                  className="font-bold font-poppins text-shadow-sm"
                  style={{ fontSize: "15px" }}
                >
                  {userInfo?.firstName} {userInfo?.lastName}
                </h1>
                <p
                  className="font-poppins text-shadow-sm px-4"
                  style={{ fontSize: "12px" }}
                >
                  ({userInfo?.countryCode}) {userInfo?.phoneNo}
                </p>
              </div>
            </div>
            <div className="w-full p-3">
              {options.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{ borderBottom: index !== 2 && "1px solid #D8D9DA" }}
                    className="flex py-4 px-1 cursor-pointer hover:bg-gray-100 transition duration-300 ease-in-out"
                    onClick={() => {
                      setIsOptionsVisible(false);
                      redirect(
                        `/user_profile/${
                          index === 0
                            ? "myProfile"
                            : index === 1
                            ? "bookings"
                            : "about"
                        }`
                      );
                    }}
                  >
                    <img src={item.src} alt="" className="mr-2" width={20} />
                    <h1
                      className="w-full"
                      style={{ fontWeight: "500", fontSize: "12px" }}
                    >
                      {item.title}
                    </h1>
                    <img src={side} alt="" width={7} />
                  </div>
                );
              })}
            </div>
            <button
              className="text-red-500 px-20 py-2 rounded-full m-4 font-semibold bg-transparent border border-red-500 hover:bg-red-500 hover:text-white hover:border-transparent  transition duration-300 ease-in-out"
              onClick={() => setDisplayLogOutModal(true)}
            >
              Logout
            </button>
          </div>
          <LogoutModal
            isVisible={displayLogOutModal}
            handleClose={() => setDisplayLogOutModal(false)}
            title={"Logout"}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default ProfileModal;
