import edit from "../../../assets/ui_elements/edit.svg";
import bookings from "../../../assets/ui_elements/book.svg";
import about from "../../../assets/ui_elements/info.svg";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
const NavButtons = () => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className="flex">
      <button
        className={`flex rounded-full px-5 py-3 mx-2 transition-all duration-300  hover:bg-blue-100 ${
          location.pathname === "/user_profile/myProfile" &&
          "bg-blue-200 border border-blue-500"
        }`}
        onClick={() => navigate("/user_profile/myProfile")}
      >
        <img src={edit} alt="" className="mr-3" />
        <p style={{ fontWeight: "500" }}>Edit Profile</p>
      </button>
      <button
        className={`flex rounded-full px-5 py-3 mx-2 transition-all duration-300  hover:bg-blue-100 ${
          location.pathname === "/user_profile/bookings" &&
          "bg-blue-200 border border-blue-500"
        }`}
        onClick={() => navigate("/user_profile/bookings")}
      >
        <img src={bookings} alt="" className="mr-3" />
        <p style={{ fontWeight: "500" }}>Booking History</p>
      </button>
      <button
        className={`flex rounded-full px-5 py-3 mx-2 transition-all duration-300  hover:bg-blue-100 ${
          location.pathname === "/user_profile/about" &&
          "bg-blue-200 border border-blue-500"
        }`}
        onClick={() => navigate("/user_profile/about")}
      >
        <img src={about} alt="" className="mr-3" />
        <p style={{ fontWeight: "500" }}>About Company</p>
      </button>
    </div>
  );
};
export default NavButtons;
