import { getToken } from "../../constant/localStorage/localStorage";
import screenLoaderAction from "../screenLoader/action";
import UserInfoActionTypes from "./userInfo_action_types";
import axios from "../../constant/axios";
import { toast } from "react-toastify";

const UserAction = {
  isUserLogin: (payload) => ({
    type: UserInfoActionTypes?.IS_USER_LOGIN,
    payload,
  }),
  userInfo: (payload) => ({
    type: UserInfoActionTypes?.USER_INFO,
    payload,
  }),
  sessionExpired: () => ({
    type: UserInfoActionTypes?.SESSION_EXPIRED,
  }),
  setDeviceToken: (payload) => ({
    type: UserInfoActionTypes?.DEVICE_TOKEN,
    payload,
  }),
  getCustomerInfo: () => (dispatch) => {
    // Change Add address Base Url Hear

    return new Promise((resolve, reject) => {
      let token = getToken();

      if (token) {
        // For Screen Loading
        dispatch(screenLoaderAction.isLoading(false));
        axios
          // Change Add address Base Url Hear
          .get("/customers/account")
          .then((res) => {
            if (res?.statusCode >= 200 && res?.statusCode <= 299) {
              // For Storing Address Data in Redux
              dispatch(UserAction.userInfo(res?.data));
              dispatch(UserAction.isUserLogin(true));

              resolve(res?.data);

              dispatch(screenLoaderAction.isLoading(false));
            } else {
              dispatch(screenLoaderAction.isLoading(false));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  },
  updateCustomer: (payload, val) => (dispatch) => {
    return new Promise((resolve, reject) => {
      let token = getToken();

      if (token) {
        // For Screen Loading
        dispatch(screenLoaderAction.isLoading(false));
        axios
          // Change Add address Base Url Hear
          .put("/customers/updateAccount", payload)
          .then((res) => {
            if (res?.statusCode >= 200 && res?.statusCode <= 299) {
              // For Storing Address Data in Redux

              dispatch(UserAction.getCustomerInfo());

              resolve(res?.data);
              if (!val) {
                toast.success("Updated!");
              }
              dispatch(screenLoaderAction.isLoading(false));
            } else {
              dispatch(screenLoaderAction.isLoading(false));
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  },
};

export default UserAction;
