import EmptyCart from "../../components/checkout/EmptyCart";
import axios from "../../constant/axios";
import { useEffect, useState } from "react";
import SubCategoryCard from "./SubCategoryCard";
import { Box, Skeleton } from "@mui/material";

const SubCategories = () => {
  const [subCategory, setSubCategory] = useState(null);

  const getSubcategories = (id) => {
    axios.get(`/category/${id}`).then((res) => {
      if (res?.statusCode >= 200 && res?.statusCode <= 299) {
        console.log(res.data.subcategories);
        setSubCategory(res.data.subcategories);
      }
    });
  };
  useEffect(() => {
    let url = window.location.pathname;
    let categoryId = url.split("/")[2];

    if (categoryId) {
      getSubcategories(categoryId);
    }

    // eslint-disable-next-line
  }, []);
  return (
    <>
      <h1 className="text-2xl font-bold my-8 mx-40">Sub Categories</h1>
      {subCategory === null && (
        <>
          <div className="flex justify-center items-center flex-wrap gap-12 my-5">
            {Array.from({ length: 3 }).map((_, index) => (
              <Box key={index}>
                <Skeleton variant="rounded" width={384} height={384} />
                <Skeleton
                  variant="rounded"
                  width={384}
                  height={384}
                  sx={{ marginTop: "30px" }}
                />
              </Box>
            ))}
          </div>
        </>
      )}

      {subCategory?.length === 0 ? (
        <div
          className="flex justify-center items-center"
          style={{ height: "90vh" }}
        >
          <EmptyCart
            title={"No Sub-Categories available"}
            description={"Try again later."}
          />
        </div>
      ) : (
        <div className="mx-40">
          <div className="flex justify-between items-center flex-wrap gap-4">
            <SubCategoryCard data={subCategory} />
          </div>
        </div>
      )}
    </>
  );
};
export default SubCategories;
