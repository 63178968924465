import "./App.css";

// Routers v6
import { BrowserRouter, Routes, Route } from "react-router-dom";

//user component
import Navbar from "./components/Navbar/Navbar";

// auth components
import Login from "./pages/auth/Login";

import Checkout from "./pages/checkout/Checkout";
import Home from "./pages/home/Home";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserProfile from "./pages/userProfile/userProfile";
import Myprofile from "./components/userProfile/Myprofile/Myprofile";
import Address from "./components/userProfile/about/AboutCompany";

import Lodding from "./components/isLodding/Lodding";
import Services from "./pages/services/Services";
import { connect, useDispatch } from "react-redux";
import ScreenLoaderTypes from "./redux/screenLoader/screen-loader-types";
import { getToken as Get_Token } from "./constant/localStorage/localStorage";
import UserAction from "./redux/userInfo/userInfo-action";
import { useEffect, useRef } from "react";
import Main from "./pages/main/Main";

import CartAction from "./redux/cart/cart-action";
import AddQuestionarie from "./pages/AddQuestionaire/AddQuestionarie";
import WelcomeModal from "./components/welcomeModal/WelcomeModal";
import ServicesUnderCategory from "./pages/serviceUnderCategory/service";
import TermsAndConditions from "./pages/TermsAndConditions/TermsAndConditions";
import { getMessaging, getToken } from "firebase/messaging";
import { messaging } from "./firebase";
import Categories from "./pages/categories/Categories";
import LogoutModal from "./components/logoutModal/LogoutModal";
import LogoutModalRefreshToken from "./components/logoutModal/LogoutModalRefreshToken";
import SubCategories from "./pages/subCategories/SubCategories";

function App(props) {
  const token = Get_Token();
  const getRoutes = () => {
    return (
      <>
        <Route path="/user_profile/myProfile" Component={Myprofile} />
        <Route path="/user_profile/address" element={<Address />} />
      </>
    );
  };

  const timeoutRef = useRef(null);

  const dispatch = useDispatch();
  useEffect(() => {
    if (token) {
      dispatch(UserAction.getCustomerInfo());
      dispatch(CartAction.getCart());
    }
  }, [token]);

  const updateDeviceToken = () => {
    if (token && props.deviceToken) {
      let payload = {
        deviceToken: props.deviceToken,
      };
      props.updateCustomer(payload, true);
    }
  };

  useEffect(() => {
    updateDeviceToken();
  }, [props.deviceToken, token]);

  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      // generate token
      const token = await getToken(messaging, {
        vapidKey:
          "BPAjX2ZDjOwAB8Tze5orUjzElIbKc40Pfqd2hXc4T1oRAL8I833GO4Rr8crV371VMpkQX8-PrXRZa_O20hFH2I0",
      });
      sessionStorage.removeItem("notificationPermission");

      dispatch(UserAction.setDeviceToken(token));
      console.log("Token Gen:", token);
    } else if (permission === "denied") {
      let notificationPermission = sessionStorage.getItem(
        "notificationPermission"
      );

      if (!notificationPermission) {
        alert("You are denied to receive push notifications");
        sessionStorage.setItem("notificationPermission", permission);
      }
    }
  }

  useEffect(() => {
    requestPermission();
  }, []);

  return (
    <>
      <BrowserRouter>
        <Navbar />

        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/landing" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route
            path="/services/pickupService"
            element={<Services pickupService={true} />}
          />
          <Route path="/categories" element={<Categories />} />
          <Route
            path="/categories/:id/subCategories"
            element={<SubCategories />}
          />
          <Route path="/login" element={<Login />} />
          <Route path="/addQuestion" element={<AddQuestionarie />} />
          <Route path="/welcome" element={<WelcomeModal />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/TermsAndConditions" element={<TermsAndConditions />} />
          <Route
            path="/category/:id/service"
            element={<ServicesUnderCategory />}
          />
          <Route
            path="/user_profile/*"
            Component={UserProfile}
            children={getRoutes}
          />
        </Routes>
        {/* <LogoutModal
          isVisible={props.isSessionExpired}
          title="Session Expired"
        /> */}
        <LogoutModalRefreshToken
          isVisible={props.isSessionExpired}
          title="Session Expired"
        />
      </BrowserRouter>
      {/* This Lodder will get attached to every page autometically 
      <Lodding /> */}
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        // pauseOnHover
      />
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  serUserLoginInfo: (val) => dispatch(UserAction.isUserLogin(val)),
  serUserInfo: (val) => dispatch(UserAction.userInfo(val)),
  updateCustomer: (val, flag) => dispatch(UserAction.updateCustomer(val, flag)),
});
const mapStateToProps = (state) => ({
  deviceToken: state.user.deviceToken,
  isSessionExpired: state.user.isSessionExpired,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
