import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import AddressReducer from "./address/reducer";
import ScreenLoaderReducer from "./screenLoader/reducer";
import UserInfoReducer from "./userInfo/reducer";
import CartReducer from "./cart/reducer";

const middleWares = [thunk];

const store = createStore(
    combineReducers({
        addressReduser: AddressReducer,
        screenLoaderReducer: ScreenLoaderReducer,
        user: UserInfoReducer,
        cart: CartReducer,
    }),
    composeWithDevTools(
        applyMiddleware(...middleWares)
    ),
);

export default store;