import { useState, useEffect } from "react";
import axios from "../../constant/axios";
import Footer from "../../components/Footer/Footer";
import CategoriesCard from "./CategoriesCard";
const Categories = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    // Getting All Services Data
    axios
      .get("/category/")
      .then((res) => {
        console.log(res.data);
        setCategories(res.data.categories);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div className="mt-8 mx-40">
        <h1 className="text-2xl font-bold">Categories</h1>
        <CategoriesCard cardsData={categories} />
      </div>
      <Footer />
    </>
  );
};
export default Categories;
