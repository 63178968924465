import React from 'react'
import visa from "../../../../assets/PaymentCardLogo/visa.png"
import masterCard from "../../../../assets/PaymentCardLogo/masterCard.png"

const CardLogo = ({ logo }) => {

    if (logo === "visa") {
        return (
            <img src={visa} style={{ width: "100%" , backgroundColor: "rgba(255, 255, 255, 0)" , }} alt="Visa" />
        )
    }
    return (
        <img src={masterCard} style={{ width: "100%" , backgroundColor: "rgba(255, 255, 255, 0)" , }} alt="Visa" />
    )
}

export default CardLogo