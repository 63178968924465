import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import { useNavigate } from "react-router-dom";
import { capitalizeString } from "../../../common/StringOpration/capitalizeString";

const PopularCard = ({ cardsData }) => {
  const redirect = useNavigate();

  const handleClick = (data) => {
    if (data.subcategories?.length === 0) {
      redirect(`/category/${data?.id}/service`);
    } else {
      redirect(`/categories/${data?.id}/subCategories`);
    }
  };
  return (
    <>
      <div className="flex  items-center flex-wrap gap-12 my-5">
        {cardsData?.length === 0 && (
          <>
            {Array.from({ length: 6 }).map((_, index) => (
              <Box key={index}>
                <Skeleton variant="rounded" width={160} height={160} />
                <Skeleton
                  variant="rounded"
                  width={160}
                  height={160}
                  sx={{ marginTop: "30px" }}
                />
              </Box>
            ))}
          </>
        )}

        {cardsData &&
          cardsData.map((item, index) => {
            return (
              <div
                key={index}
                className="w-40 h-40 relative border bg-black rounded-lg cursor-pointer"
                style={{
                  background: `url(${item.categoryIcon})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
                onClick={() => handleClick(item)}
              >
                <div className="absolute inset-0 flex flex-col items-center justify-end">
                  <h1 className="relative text-center p-2 w-full text-white bg-gray-400 bg-opacity-50 backdrop-blur-lg rounded-b-lg">
                    {capitalizeString(item?.title)}
                  </h1>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default PopularCard;
