import { useState, useEffect } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import dayjs from "dayjs";

function formatDate(dateObj) {
  if (!dateObj) {
    return "";
  }

  const { day, month, year } = dateObj;

  const date = new Date(year, month - 1, day);

  const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 2)
    .toString()
    .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;

  return formattedDate;
}

const CalendarInput = ({
  close,
  details,
  next,
  scheduled,
  availableTime,
  convertISOTimeToLocaleTime,
  bookingSlotDateTime,
  selectedDate,
  setSelectedDate,
  time,
  setTime,
  date,
  setDate,
}) => {
  console.log("bookingSlotDateTime => ", bookingSlotDateTime);

  //! bookingSlotDate:"2023-09-21T00:00:00.000Z"
  //! bookingSlotTime: "23:30"

  useEffect(() => {
    if (
      bookingSlotDateTime?.bookingSlotTime &&
      bookingSlotDateTime?.bookingSlotDate
    ) {
      setTime(bookingSlotDateTime?.bookingSlotTime);
      setSelectedDate(dayjs(bookingSlotDateTime?.bookingSlotDate));
    }
  }, []);

  const [error, setError] = useState({
    date: true,
    time: true,
  });

  const [touched, setTouched] = useState({
    date: false,
    time: false,
  });

  useEffect(() => {
    details({
      date: date,
      time: time,
    });
  }, [time, date]);

  const dateSelecter = (date) => {
    setTouched({
      ...touched,
      date: true,
    });
    console.log("dateSelecter => ", date);
    setSelectedDate(date);
    setDate(
      formatDate({
        day: date.$D,
        month: date.$M,
        year: date.$y,
      })
    );
    setError({
      ...error,
      date: false,
    });
  };

  const timeSelecter = (time) => {
    setTouched({
      ...touched,
      time: true,
    });
    setTime(time);
    setError({
      ...error,
      time: false,
    });
  };

  const handelContinueButton = () => {
    if (scheduled !== null) {
      if (scheduled.date !== null && scheduled.time !== null) {
        next();
      } else {
        setTouched({
          date: true,
          time: true,
        });
      }
    } else {
      setTouched({
        date: true,
        time: true,
      });
    }
  };

  let formatedTime = availableTime;

  function compareISOTimeStrings(time1, time2) {
    const date1 = new Date(`2000-01-01T${time1}:00.000Z`);
    const date2 = new Date(`2000-01-01T${time2}:00.000Z`);

    if (date1 < date2) {
      return false;
    } else {
      return true;
    }
  }

  function extractTimeFromISOString(isoTimeString) {
    const isoTimeParts = isoTimeString.split(".")[0].split(":");
    const hours = isoTimeParts[0];
    const minutes = isoTimeParts[1];
    return `${hours}:${minutes}`;
  }

  function separateDateAndTime(isoString) {
    const [datePart, timePart] = isoString.split("T");
    return { date: datePart, time: extractTimeFromISOString(timePart) };
  }

  function getCurrentDateTime() {
    const currentDate = new Date();
    const currentDateTimeISO = currentDate.toISOString();
    return currentDateTimeISO;
  }

  const currentDateTime = separateDateAndTime(getCurrentDateTime());

  if (currentDateTime.date === date) {
    console.log(currentDateTime);
    formatedTime = availableTime.filter((item) => {
      if (compareISOTimeStrings(item, currentDateTime.time)) {
        return item;
      }
    });
  }

  return (
    <div>
      <div className="flex justify-between items-center">
        <h1 className="text-xl font-bold">Select Date</h1>
        <p
          className="text-sm text-blue-500 cursor-pointer"
          style={{ fontWeight: "500" }}
          onClick={() => close()}
        >
          Back
        </p>
      </div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateCalendar
          value={selectedDate}
          disablePast
          onChange={(e) => dateSelecter(e)}
          sx={{
            "& .Mui-selected": {
              borderRadius: "5px",
            },
          }}
        />
      </LocalizationProvider>
      {touched.date && error.date && (
        <p className="text-red-500 text-center">Select Date First</p>
      )}
      <h1 className="text-xl font-bold">Select Time</h1>
      <div className="flex justify-between items-center flex-wrap">
        {date !== null ? (
          formatedTime &&
          formatedTime.map((item, index) => {
            return (
              <button
                key={index}
                className={`border border-blue-500 px-2 text-sm rounded-full text-blue-500 ${
                  time === item
                    ? "bg-blue-500 hover:bg-blue-600 text-white"
                    : "hover:bg-blue-500 hover:text-white hover:cursor-pointer"
                } mt-2`}
                onClick={() => timeSelecter(item)}
              >
                {convertISOTimeToLocaleTime(item)}
              </button>
            );
          })
        ) : (
          <h1 className="border border-blue-500 px-2 text-sm rounded-full text-blue-500 mt-3">
            Select date first
          </h1>
        )}
      </div>
      {touched.time && error.time && (
        <p className="text-red-500 text-center mt-4">Select Time First</p>
      )}
      <button
        className="rounded-full bg-blue-500 text-white w-full h-10 hover:bg-blue-600 transition duration-300 ease-in-out mt-5"
        onClick={handelContinueButton}
      >
        Continue
      </button>
    </div>
  );
};
export default CalendarInput;
