import React from "react";
import Modal from "../../common/Modal/modal";
import "./logoutModule.scss";
import { useNavigate } from "react-router-dom";

const LogoutModalRefreshToken = ({ isVisible, handleClose, title }) => {
  const redirect = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("Customer_Access_Token");
    localStorage.removeItem("Customer_Refresh_Token");
    redirect("/");
    window.location.reload();
  };
  return (
    <>
      <Modal isVisible={isVisible} title={title} onClose={handleClose}>
        <div className="flex flex-col items-center px-8">
          <div
            style={{ fontWeight: "500", fontSize: "19px", margin: "1rem 0" }}
          >
            Your Session is Expired please login again to continue
          </div>
          <div className="flex justify-between items-center gap-3 mt-5 w-full px-5">
            <button
              className="border w-1/2 py-2 rounded-full border-blue-500 text-blue-500 hover:bg-blue-100"
              style={{ fontWeight: "500" }}
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              className="border w-1/2 py-2 rounded-full bg-blue-700 border-blue-500 text-white hover:bg-blue-800"
              style={{ fontWeight: "500" }}
              onClick={handleLogout}
            >
              Login
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default LogoutModalRefreshToken;
