import { useEffect } from "react";

function formatISOToCustomDateTime(isoString) {
  const date = new Date(isoString);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const month = months[date.getMonth()];
  const day = date.getDate();
  const weekday = weekdays[date.getDay()];
  const hours = date.getHours() % 12 || 12;
  const minutes = date.getMinutes();
  const ampm = date.getHours() >= 12 ? "PM" : "AM";

  const formattedDate = `${month} ${day}(${weekday}), ${hours}:${minutes
    .toString()
    .padStart(2, "0")} ${ampm}`;

  return formattedDate;
}

const ActivityButton = ({
  data,
  setShowCalender,
  formatDateTime,
  scheduled,
  setShowForm,
  pickupDetails,
  setValid,
  display,
}) => {
  useEffect(() => {
    if (scheduled || pickupDetails?.timeOfArrival) {
      setValid(true);
    }
  }, []);
  return (
    <>
      {data?.isPickupService ? (
        <button
          className="rounded-full text-blue-500 w-full h-10 transition duration-300 ease-in-out mt-4 border border-blue-500 disabled:cursor-not-allowed"
          onClick={() => setShowForm(true)}
          disabled={display}
        >
          {pickupDetails?.timeOfArrival
            ? formatISOToCustomDateTime(pickupDetails?.timeOfArrival)
            : pickupDetails?.timeOfArrival
            ? formatISOToCustomDateTime(pickupDetails?.timeOfArrival)
            : "Select Date & Time"}
        </button>
      ) : (
        <button
          className="rounded-full text-blue-500 w-full h-10 transition duration-300 ease-in-out mt-4 border border-blue-500 disabled:cursor-not-allowed"
          onClick={() => setShowCalender(true)}
          disabled={display}
        >
          {scheduled !== null
            ? formatDateTime(scheduled)
            : pickupDetails?.bookingSlotDate
            ? formatDateTime({
                date: pickupDetails?.bookingSlotDate,
                time: pickupDetails?.bookingSlotTime,
              })
            : "Select Date & Time"}
        </button>
      )}
    </>
  );
};
export default ActivityButton;
