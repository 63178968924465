import styles from "../auth.module.css";

import { useState } from "react";

import Validations from "../../../helper/validations/validations";
// import ShowError from "../../error/ShowError";
import Input from "../../../common/Input/input";
import IsValidate from "../../../common/isValidate/isValidate";
import axios from "../../../constant/axios";
import { connect } from "react-redux";
import UserAction from "../../../redux/userInfo/userInfo-action";

import logo from "../../../assets/Logo.svg";
import { setUserInfo } from "../../../constant/localStorage/localStorage";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const LoginForm = (props) => {
  const redirect = useNavigate();

  const [checkValidation, setCheckValidation] = useState(false);

  const [isChecked, setIsChecked] = useState(false);
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState(false);

  const [loginDetails, setLoginDetails] = useState({
    userName: Validations.validateTextField(""),
    password: Validations.validatePassword(""),
  });

  const handleFieldChange = (e) => {
    const { name, value } = e?.target;
    switch (name) {
      case "userName":
        return setLoginDetails({
          ...loginDetails,
          userName: Validations.validateTextField(value),
        });
      case "password":
        return setLoginDetails({
          ...loginDetails,
          password: Validations.validatePassword(e.target.value),
        });
      default:
        return;
    }
  };

  const handleSkip = () => {
    redirect("/landing");
  };

  const loginSubmitHandler = (e) => {
    e.preventDefault();

    setCheckValidation(true);

    if (!loginDetails.userName.isValid || !loginDetails.password.isValid) {
      return;
    }

    const url = "/customers/login";
    // deviceType
    // deviceToken
    const payload = {
      username: loginDetails.userName.value,
      password: loginDetails.password.value,
      deviceType: "web",
    };
    if (props?.deviceToken) {
      payload.deviceToken = props?.deviceToken;
    }

    axios
      .post(url, payload)
      .then((res) => {
        console.log("loginSubmitHandler => ", res?.data);
        if (res?.statusCode >= 200 && res?.statusCode <= 299) {
          if (
            !res?.data?.isQuestionAdded ||
            (res?.data?.isQuestionAdded && res?.data?.isAllAnswerSubmitted)
          ) {
            toast.success("Login Successful");
            redirect("/welcome");
          } else if (
            res?.data?.isQuestionAdded &&
            !res?.data?.isAllAnswerSubmitted
          ) {
            redirect("/addQuestion");
          }

          // Saving the refresh token here //
          if (res?.data?.refreshToken) {
            localStorage.setItem(
              "Customer_Refresh_Token",
              res.data.refreshToken
            );
          }
          setUserInfo(res?.data);

          props.serUserLoginInfo(true);
          props.serUserInfo(res?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className={styles.authForm}>
        <img src={logo} alt="" />
        <div className="flex flex-col gap-4">
          <h1 className="text-center  font-semibold text-[2rem]">Log In</h1>
          <p className=" text-[#666] text-[1rem] m-0">
            Please login with the credentials provided to you
          </p>
        </div>
        <div className="flex flex-col space-y-1 w-full">
          <Input
            isValied={loginDetails?.userName?.isValid}
            errorMessage={loginDetails?.userName?.errMsg}
            value={loginDetails?.userName?.value}
            name="userName"
            onChange={handleFieldChange}
            checkValidation={checkValidation}
            placeholder="Username"
            className="bg-gray-100 border-none rounded-2xl w-full h-12 mt-5"
          />
          {checkValidation && !loginDetails.userName?.isValid && (
            <IsValidate text={loginDetails.userName?.errMsg} />
          )}
        </div>

        <div className="flex flex-col space-y-1 w-full">
          <Input
            isValied={loginDetails?.password?.isValid}
            errorMessage={loginDetails?.password?.errMsg}
            value={loginDetails?.password?.value}
            name="password"
            type="password"
            onChange={handleFieldChange}
            checkValidation={checkValidation}
            placeholder="Password"
            className="bg-gray-100 border-none rounded-2xl w-full h-12 mt-5"
          />
          {checkValidation && !loginDetails.password?.isValid && (
            <IsValidate text={loginDetails.password?.errMsg} />
          )}
        </div>
        <div className="flex items-center mt-5 pl-2 w-full">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
              className="mr-1"
            />
            <a
              className="text-blue-700 hover:underline transform hover:scale-105 transition duration-300"
              href="https://admin.theexcursionist.net/terms-and-conditions"
              target="_blank"
            >
              Terms and Conditions
            </a>
          </label>
        </div>
        <div className="flex items-center mt-5 pl-2 w-full">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={privacyPolicyChecked}
              onChange={() => setPrivacyPolicyChecked(!privacyPolicyChecked)}
              className="mr-1"
            />
            <a
              className="text-blue-700 hover:underline transform hover:scale-105 transition duration-300"
              href="https://admin.theexcursionist.net/privacy-policy"
              target="_blank"
            >
              Privacy Policy
            </a>
          </label>
        </div>

        <div className="flex justify-center items-center w-full flex-col mt-4">
          <button
            className="w-full px-16 py-3 bg-[#1560BD] flex justify-center items-center mt-2 text-white rounded-full cursor-pointer disabled:bg-gray-300 disabled:text-black disabled:cursor-not-allowed"
            onClick={loginSubmitHandler}
            disabled={!isChecked || !privacyPolicyChecked}
          >
            Login
          </button>

          <button
            className="w-full px-16 py-3 text-[#1560BD] border border-[#1560BD] flex justify-center items-center mt-2 bg-white rounded-full cursor-pointer"
            onClick={handleSkip}
          >
            Skip
          </button>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  serUserLoginInfo: (val) => dispatch(UserAction.isUserLogin(val)),
  serUserInfo: (val) => dispatch(UserAction.userInfo(val)),
});
const mapStateToProps = (state) => ({
  deviceToken: state.user.deviceToken,
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
