import React, { useState } from "react";
import Modal from "../../../common/Modal/modal";
import Card from "../bookingCard/Card";

const BookingDetails = ({ isVisible, data, handleClose }) => {
  console.log(data?.bookedServices);
  return (
    <Modal
      isVisible={isVisible}
      width={"30rem"}
      title={"Booking Detail"}
      onClose={handleClose}
    >
      <div
        className="px-4 py-4 flex flex-col gap-4 h-96 overflow-scroll"
        style={{ scrollBehavior: "smooth" }}
      >
        <div className=" flex flex-col items-center gap-4">
          {data ? (
            data.bookedServices &&
            Array.isArray(data?.bookedServices) &&
            data?.bookedServices.map((obj) => {
              return (
                <>
                  <Card
                    data={obj}
                    description={obj?.description}
                    bookingDetail={true}
                    total={obj?.actualPrice}
                    serviceImg={obj?.serviceImg}
                    handleClose={handleClose}
                    compact={true}
                  />
                </>
              );
            })
          ) : (
            <></>
          )}
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-4 border-b-2 border-[#F4F4F4] pb-2">
            <div className="text-[1.25rem] font-semibold">Amount Breakdown</div>

            <div className="flex items-center justify-between text-[1.15rem] font-semibold">
              <span>Price</span>
              <span>$ {data?.totalActualPrice}</span>
            </div>
            <div className="flex items-center justify-between text-[1.15rem] font-semibold">
              <span>Promo Discount</span>
              <span>-$ {data?.couponDiscount}</span>
            </div>
            <div className="flex items-center justify-between text-[1.15rem] font-semibold">
              <span>Discount</span>
              <span>-$ {data?.discount}</span>
            </div>
          </div>

          <div className="flex justify-between gap-4 border-b-2 border-[#F4F4F4] pb-2">
            <div className="text-[1.25rem] font-semibold">Grand Total</div>
            <div className="text-[1.25rem] font-semibold">
              $ {data?.paymentDetails?.amount}
            </div>
          </div>

          {data?.bookingStatus === "CONFIRMED" ? (
            <div className="p-1 text-center bg-[#18BC34] text-white text-[1.25rem] cursor-pointer font-semibold rounded-md">
              Payment Successful
            </div>
          ) : (
            <div className="p-1 text-center bg-red-700 text-white text-[1.25rem] cursor-pointer font-semibold rounded-md">
              Booking Cancelled
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default BookingDetails;
